import React from 'react'

function landing() {

  
    return (
      <div>
        <section id="landing-page-buy-sell-mint-section_1">
          <div id="landing-page-buy-sell-mint-section_1-container" className="landing-page-buy-sell-mint-container container-lg md-mt-12">
            <div className="mx-lg-8 mx-3">
              <div className="row no-gutters justify-content-lg-start justify-content-sm-center mb-6 mt-6">
                <div className="col-lg-6 col-md-8 co-sm-12 mx-4 text-lg-left text-center">
                  <h1 className="font-weight-bold content-header mb-3">Buy, sell and mint fractions of NFTs.?</h1>
                  <p className="big">Fractional ownership of the world’s most sought after NFTs. Fractional reduces entry costs, increases access, and allows for synergy with other DeFi primitives.</p>
                  <button className="btn btn-secondary btn-rounded w-212px mt-lg-5 mt-4 mb-2" type="button"><img className="view-demo-icon-img img-fluid mr-2" src="images/view-demo-icon-img_1.svg" />View Demo</button>
                  <div className="go-down-container mt-lg-6 mt-4 mb-2"><a className href="#"><img className="view-demo-icon-img img-fluid mr-2" src="images/go-down-img_1.svg" /></a></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Why Aqarchain.io? SECTION*/}
        <section id="landing-page-why-aqarchain-section_3" className="why-aqarchain-section">
          <div id="landing-page-section_3-container" className="landing-page-why-aqarchain-container container-fluid bg-secondary">
            <div className="mx-lg-8 mx-3">
              <div className="row no-gutters justify-content-lg-center mb-6 mt-6">
                <div className="col-lg-10 col-md mx-4 text-center">
                  <div className="why-aqarchain-container">
                    <h1 className="font-weight-bold text-center content-header mb-3 text-white">Why Aqarchain.io?</h1>
                    <p className="big text-white">Aqarchain.io delivers a unique way to unlock NFT liquidity and provides 
                      <br />community access to owning parts of iconic and historic Real Estate NFTs.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="landing-page-section_3-container-four-blocks" className="landing-page-why-aqarchain-container-four-blocks container-lg mb-6">
            <div className="mx-xl-8 mx-lg-5 mx-3 mt-sm-6 mb-sm-6">
              <div className="row row-cols-1 row-cols-md-4 g-4">
                <div className="col-sm-12 col-md-6 col-lg-3 mt-2 mb-2">
                  <div className="card h-100 service-block four-blocks">
                    <div className="service-img four-blocks">
                      <p><img className="img-yellow partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-accessibility_yellow.svg" /></p>
                      <p><img className="img-white partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-accessibility_white.svg" /></p>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title font-weight-bold mb-3">Accessibility</h3>
                      <p className="card-text big">Become a partial owner of collectable NFTs you otherwise could not afford</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 mt-2 mb-2">
                  <div className="card h-100 service-block four-blocks">
                    <div className="service-img four-blocks">
                      <p><img className="img-yellow partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-freedom_yellow.svg" /></p>
                      <p><img className="img-white partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-freedom_white.svg" /></p>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title font-weight-bold mb-3">Freedom</h3>
                      <p className="card-text big">Decide what to do with your NFT fractions (e.g., add the underlying NFT to a digital gallery, deliver to contest winners)</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 mt-2 mb-2">
                  <div className="card h-100 service-block four-blocks">
                    <div className="service-img four-blocks">
                      <p><img className="img-yellow partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-diversity_yellow.svg" /></p>
                      <p><img className="img-white partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-diversity_white.svg" /></p>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title font-weight-bold mb-3">Diversity</h3>
                      <p className="card-text big">Spread the portfolio into a wide range of selected assets belonging to different range in PRICE, ROI and YEILD. </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 mt-2 mb-2">
                  <div className="card h-100 service-block four-blocks">
                    <div className="service-img four-blocks">
                      <p><img className="img-yellow partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-creativity_yellow.svg" /></p>
                      <p><img className="img-white partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-creativity_white.svg" /></p>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title font-weight-bold mb-3">Creativity</h3>
                      <p className="card-text big">Deliver imaginative distribution events and novel post-purchase community experiences for your fraction owners</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* How It Works SECTION*/}
        <section id="landing-page-how-it-works-section_4" className="how-it-works-section bg-light-blue-gradient">
          <div id="landing-page-section_4-how-it-works-container" className="how-it-works-section-container container-lg">
            <div className="mx-lg-8 mx-3">
              <div className="row no-gutters justify-content-md-center mb-md-6 mb-sm-3">
                <div className="col-lg-10 col-md mx-4 text-center">
                  <h1 className="font-weight-bold text-center content-header mb-3">How it works</h1>
                  <p className="extra-large">Built by NFT collectors, Fractional enables both NFT market value discovery and NFT tokenization which unlocks collectable access for smaller collectors and synergies with other DeFi primitives</p>
                </div>
              </div>
              <div className="row no-gutters mt-2 mb-2 align-items-center">
                <div className="col-lg-6 col-12">
                  <img className="img-fluid" src="images/how-it-works-section-buy-artwork_1.svg" />
                </div>
                <div className="col-lg-6 col-12">
                  <div className="text-md-left text-center p-3">
                    <h2 className="content-subheader font-weight-bold mt-lg-4 mt-2 mb-4 alt-brand-blue">Become a fractional owner and enjoy the returns with min. investment</h2>
                    {/* <p className="big font-normal">Purchase fractions to collect and own a percentage of your favorite real estate NFTs</p> */}
                  </div>
                </div>
              </div>
              <div className="row no-gutters mt-2 mb-2 align-items-center">
                <div className="col-lg-6 col-12 order-lg-6">
                  <img className="img-fluid" src="images/how-it-works-section-vote-artwork_1.png" />
                </div>
                <div className="col-lg-6 col-12 order-lg-1">
                  <div className="text-md-left text-center p-3">
                    <h2 className="content-subheader font-weight-bold mt-lg-4 mt-2 mb-4 alt-brand-blue">VOTE on your own property decisions by staking AQR Tokens.</h2>
                    {/* <p className="big font-normal">Contribute to the reserve price  the NFofT collections you buy</p> */}
                  </div>
                </div>
              </div>
              <div className="row no-gutters mt-2 mb-2 align-items-center">
                <div className="col-lg-6 col-12">
                  <img className="img-fluid" src="images/how-it-works-section-decide-artwork_1.png" />
                </div>
                <div className="col-lg-6 col-12">
                  <div className="text-md-left text-center p-3">
                    <h2 className="content-subheader font-weight-bold mt-lg-4 mt-2 mb-4 alt-brand-blue">Be a part of the ecosystem and connect with global investors through fractionalisation.</h2>
                    {/* <p className="big font-normal">Some description goes here to back the title above. It shouldn’t be longer than two or three rows in order to look nice.</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Partners and Supported By SECTION*/}
        <section id="landing-page-partner-and-supported-section_5" className="partner-and-supported-by-section bg-dark-blue-gradient">
          <div id="landing-page-section_5-partner-and-supported-container" className="partner-and-supported-by-section-container container-lg">
            <div className="row no-gutters">
              <div className="col-lg-12 col-12  text-center">
                <h1 className="font-weight-bold text-white text-center content-header mb-5">Partners and Supported By</h1>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-lg-12 col-12">
                <div className="has-box-shadow border-radius-30px p-lg-5 p-4 bg-white mx-lg-5 mx-3">
                  <div className="row">
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/01.png" />
                    </div>
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/02.png" />
                    </div>
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/03.png" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/04.png" />
                    </div>
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/05.png" />
                    </div>
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/06.png" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/07.png" />
                    </div>
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/08.png" />
                    </div>
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/09.png" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/10.png" />
                    </div>
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/11.png" />
                    </div>
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/12.png" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/13.png" />
                    </div>
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/14.png" />
                    </div>
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/15.png" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/16.png" />
                    </div>
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/17.png" />
                    </div>
                    <div className="col-lg col-sm-12 text-center">
                      <img className="partner-and-supported-logo-img img-fluid" src="images/clients-logos/18.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*FAQ SECTION*/}
        {/* <section id="landing-page-faq-section_6" className="faq-section bg-light-blue-gradient">
          <div id="landing-page-faq-section_6-container" className="faq-section-container container-lg">
            <div className="row no-gutters">
              <div className="col-lg-12 col-12  text-center">
                <h1 className="font-weight-bold text-center content-header">Frequently Asked Questions</h1>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-lg-12 col-12">
                <div className="accordion mx-lg-9 my-lg-5 mx-3" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <p className="mb-0 extra-big">
                        What is the common feature of learning?
                      </p>
                      <i className="fa fa-plus mr-4" />
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>Education gives us a knowledge of the world around us and changes it into something better. It develops in us a perspective of looking at life. It helps us build opinions and have points of view on things in life. Education gives us a knowledge of the world around us and changes it into something better. It develops in us a perspective of looking at life. It helps us build opinions and have points of view on things in life.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <p className="mb-0 extra-big">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      </p>
                      <i className="fa fa-plus mr-4" />
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <p className="mb-0 extra-big">
                        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece
                      </p>
                      <i className="fa fa-plus mr-4" />
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFour" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      <p className="mb-0 extra-big">
                        There are many variations of passages of Lorem Ipsum available, but the majority
                      </p>
                      <i className="fa fa-plus mr-4" />
                    </div>
                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingFive" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      <p className="mb-0 extra-big">
                        What are the 3 types of education?
                      </p>
                      <i className="fa fa-plus mr-4" />
                    </div>
                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                      <div className="card-body">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    )
}

export default landing
