import React, { } from 'react'
import {
  WalletElement,
  WalletItem,
  WalletIcon,
  WalletName,
  Img,
} from './Wallet.elements'

import metamaskIcon from '../../assets/img/metamask.png'
import fortmaticIcon from '../../assets/img/fortmatic.png'
import walletconnectIcon from '../../assets/img/walletconnect.svg'
import coinbaseWalletIcon from '../../assets/img/coinbaseWalletIcon.svg'
import { useWeb3React } from '@web3-react/core'
import {
  injected,
  walletlink,
  walletconnect,
  fortmatic,
} from '../../connectors'

const Wallets = ({ setWalletOpen }) => {
  const walletList = [
    {
      name: 'MetaMask',
      icon: metamaskIcon,
    },
    {
      name: 'WalletConnect',
      icon: walletconnectIcon,
    },
    {
      name: 'Coinbase',
      icon: coinbaseWalletIcon,
    },
    // {
    //   name: 'Fortmatic',
    //   icon: fortmaticIcon,
    // },
  ]
  const { activate } = useWeb3React()
  const connectWithWallet = async (wallet) => {
    try {
      await activate(wallet).then(() => {
        setWalletOpen(false)
      })
    } catch (error) {
    }
  }

  return (
    <WalletElement>
      {walletList.map((item, index) => {
        return (
          <>
            <WalletItem
              // className={`${
              //   item.name === 'MetaMask' &&
              //   library?.connection.url === 'metamask'
              //     ? 'connected'
              //     : ''
              // }`}
              key={index}
              onClick={() =>
                connectWithWallet(
                  (item.name === 'MetaMask' && injected) ||
                  (item.name === 'WalletConnect' && walletconnect) ||
                  (item.name === 'Coinbase' && walletlink) ||
                  (item.name === 'Fortmatic' && fortmatic)
                )
              }>
              <WalletName>{item.name}</WalletName>
              <WalletIcon>
                <Img src={item.icon} />
              </WalletIcon>
            </WalletItem>
          </>
        )
      })}
    </WalletElement>
  )
}

export default Wallets
