import { Formik } from 'formik';
import React from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { countryCode } from '../../Config/constant';
import { sendOTP } from '../../Redux/action/Auth';




const Step = ( props ) => {
    const { setActiveStep } = props
    const dispatch = useDispatch()



    return (
        <div className="py-md-7 p-5 mx-3 has-box-shadow">
            <div className="form-heading my-6">
                <h3 className="font-weight-bold text-dark">
                    Confirm your mobile number
                </h3>
                <h3 className="font-weight-bold text-dark">
                    for seam less experience
                </h3>
            </div>
            <Formik
                initialValues={{
                    country_code: "",
                    mobile: ""
                }}
                onSubmit={async ( values ) => {
                    const { country_code, mobile } = values
                    if ( !country_code ) {
                        toast.error( "Enter Country Code" )
                        return
                    } else if ( !mobile ) {
                        toast.error( "Enter Mobile Number" )
                        return
                    } else {
                        await dispatch( sendOTP( values ) )
                            .then(
                                response => {
                                    console.log( response );
                                    setActiveStep( 1 )
                                    dispatch( { type: "SAVE_USER_NUMBER", payload: values } )
                                },
                                error => {
                                    toast.error( error.response.data.message )
                                }
                            )
                            .catch(
                                error => console.log( error )
                            )
                    }
                }}
            >
                {( {
                    values,
                    handleChange,
                    handleSubmit
                } ) => (
                    <form className="my-5" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-3 col-12">
                                <div className="form-group">
                                    <select
                                        onChange={handleChange}
                                        value={values.country_code}
                                        name="country_code"
                                        className="form-control secondary-select" id="cc">
                                        <option>CC</option>
                                        {
                                            countryCode.map( ( item, index ) => {
                                                return ( <option key={index} value={item.dial_code}>
                                                    {`${item.dial_code}`}
                                                </option> );
                                            } )
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-9 col-12">
                                <div className="form-group">
                                    <input
                                        onChange={handleChange}
                                        name="mobile"
                                        type="text"
                                        value={values.mobile}
                                        className="form-control secondary-input"
                                        id="mobileNumber"
                                        placeholder="Mobile Number"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <button
                                id="send-otp-button"
                                className="btn btn-gradient-secondary w-100 my-5"
                            >
                                Send OTP
                            </button>
                        </div>
                    </form>
                )}
            </Formik>

            <small className="form-text text-muted text-center">
                You already have an account?{' '}
                <span
                    className="font-weight-bold text-muted">
                    <Link to={'/login'}>Click here to login</Link>
                </span>
            </small>
        </div>
    )
}

export default Step
