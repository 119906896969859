import sample from 'lodash/sample'

// Array of available nodes to connect to
export const nodes = [
  'https://rpc-mainnet.maticvigil.com/v1/938d4a7445ee015dbca9926beee1d9ab0d36da66',
]

const getNodeUrl = () => {
  return sample(nodes)
}

export default getNodeUrl
