import React from 'react'

const Profile = () => {

  const editProfile = true
  
  return (
     <div
        className={`tab-pane ${
          !editProfile ? 'my-profile-view' : ''
        } show active`}
        id="company-nav-tab-my-profile"
        role="tabpanel"
        aria-labelledby="company-nav-tab-my-profile-tab">
        <div className="text-lg-left p-4 p-lg-5 has-box-shadow has-border-radius">
          <div className="row d-lg-flex align-items-center">
            <div className="col-lg-6 col-12">
              <h3 className="mb-3 mb-lg-3 font-weight-bold text-lg-left text-center">
                My Profile
              </h3>
            </div>
            <div className="col-lg-6 col-12 text-right">
              <div
                className={`edit-button ${editProfile ? 'd-none' : ''}`}
                id="edit-profile-btn"
              // onClick={toggleProfileView}
            >
                <span>Edit</span>
                <img alt="" src="public/images/icon-edit.svg" />
              </div>
            </div>
          </div>

          <div className="row my-4">
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label htmlFor="first-name">First Name</label>
                <input
                  // onChange={e => setFirstName(e.target.value)}
                  // value={firstName}
                  type="text"
                  className="form-control secondary-input"
                  id="first-name"
                  placeholder="Enter First Name"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label htmlFor="last-name">Last Name</label>
                <input
                  // onChange={e => setLastName(e.target.value)}
                  // value={lastName}
                  type="text"
                  className="form-control secondary-input"
                  id="last-name"
                  placeholder="Enter Last Name"
                />
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-6 col-12">
              <label>Mobile Number</label>
              <div className="form-group form-inline">
                <select
                  readOnly
                  className="form-control secondary-select mr-lg-2"
                  id="mobile-no-prepend">
                  {/* <option readOnly>+{country_code}</option> */}
                </select>
                <input
                  // value={mobile}
                  readOnly
                  type="text"
                  className="form-control secondary-input"
                  id="mobile-no"
                  placeholder="Enter Mobile Number"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label htmlFor="email-id">Email ID</label>
                <input
                  // value={email}
                  readOnly
                  type="email"
                  className="form-control secondary-input"
                  id="email-id"
                  placeholder="Enter Email ID"
                />
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label htmlFor="country">Country</label>
                {/* {editProfile ? (
                  <Country onChange={setCountry} />
                ) : (
                  <div>{country}</div>
                )} */}
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label htmlFor="state">State</label>
                {/* {editProfile ? (
                  <State
                    onChange={selectedOption => setStateValue(selectedOption)}
                    data={stateData}
                  />
                ) : (
                  <div>{state}</div>
                )} */}
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-lg-6 col-12">
              <div className="form-group">
                <label htmlFor="city">City</label>
                {/* {editProfile ? (
                  <City
                    onChange={selectedOption => setCity(selectedOption.label)}
                    data={cityData}
                  />
                ) : (
                  <div>{city}</div>
                )} */}
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-lg-4 col-md-6 col-12">
              {/* <button
                className={`btn btn-gradient-secondary ${
                  !editProfile ? 'd-none' : ''
                } w-100`}
                id="save-profile-btn"
                onClick={handleSubmit}>
                Save
              </button> */}
            </div>
          </div>
        </div>
      </div>
  )
}

export default Profile
