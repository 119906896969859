import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import ReactFileReader from 'react-file-reader';
import { getStateData, getCityData } from '../../Redux/action/App';
import { registerUser } from "../../Redux/action/Auth";
import { optionsCountry } from '../../Config/constant';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const Step4 = ( props ) => {

    const dispatch = useDispatch();
    const { setActiveStep } = props

    const history = useHistory()
    const { auth, app } = useSelector( state => state )
    const [userData, setUserData] = useState( {
        profile_pic: "",
        first_name: "",
        last_name: "",
        country: "",
        state: "",
        city: "",
    } )

    const handleProfileUpload = files => {
        setUserData( { ...userData, profile_pic: files.base64 } )
    };

    const submitHandler = async () => {
        await dispatch( registerUser( { ...auth.tempData, ...userData }, auth.token ) )
            .then(
                response => {
                    dispatch( { type: "LOGOUT" } )
                    toast.success( response.message );
                    history.push( "/login" )
                    
                },
                error => {
                    if ( Array.isArray(error.response.data.message) ) {
                        error.response.data.message.map( item => {
                            toast.error(item);
                        })
                    } else {
                        toast.error(error.response.data.message)
                    }
                }
            )
            .catch()
    }

    return (
        <div className="py-md-5 p-5 mx-3 mx-3 has-box-shadow text-center">
            <div className="row">
                <div className="col-6">
                    <div className="text-left">
                        <h4 onClick={e => setActiveStep( 3 )} style={{
                            width: "fit-content", cursor: "pointer"
                        }}>
                            <span> &laquo; </span>Go Back
                        </h4>
                    </div>
                </div>
                <div className="col-6">
                    <div className="text-right">
                        <h4>
                            Skip this for later{' '}
                            <span>
                                {' '}
                                <img
                                    alt=""
                                    className="skip-for-later ml-1"
                                    src="/images/skip-for-later.svg"
                                />
                            </span>
                        </h4>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <label className="" htmlFor="company-logo">
                    <div className="image-upload">
                        <img
                            alt=""
                            className="upload-photo-icon mx-auto cursor"
                            src={userData.profile_pic ? userData.profile_pic : "/images/upload-photo.svg"}
                        />
                        <ReactFileReader
                            fileTypes={['.png', '.jpg', '.jpeg']}
                            base64="true"
                            handleFiles={handleProfileUpload}
                        >
                            <button className="btn" id="company-logo">
                                <p className="mt-3">Upload your profile picture</p>
                            </button>
                        </ReactFileReader>
                    </div>
                </label>
            </div>
            <form className="my-4">
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control secondary-input"
                        id="first-name"
                        placeholder="First Name"
                        value={userData.first_name}
                        onChange={e => {
                            setUserData( { ...userData, first_name: e.target.value } )
                        }}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control secondary-input"
                        id="last-name"
                        placeholder="Last Name"
                        value={userData.last_name}
                        onChange={e => {
                            setUserData( { ...userData, last_name: e.target.value } )
                        }}
                    />
                </div>
            </form>
            <form className="my-2">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="form-group">
                            <Select
                                options={optionsCountry}
                                onChange={e => {
                                    dispatch( getStateData( { countryId: e.value } ) );
                                    setUserData( { ...userData, country: e.label } )
                                }} />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="form-group">
                            <Select
                                options={app.stateData}
                                onChange={e => {
                                    dispatch( getCityData( { stateId: e.value } ) );
                                    setUserData( { ...userData, state: e.label } )
                                }} />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="form-group">
                            <Select options={app.cityData}
                                onChange={e => {
                                    setUserData( { ...userData, city: e.label } )
                                }}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <button
                id="signup-button"
                className="btn btn-gradient-secondary w-100 my-5"
                onClick={submitHandler}
            >
                Get Started
            </button>
        </div>
    )
}

export default Step4
