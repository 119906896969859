import { combineReducers } from 'redux';

import auth from './Auth';
// import config from './Config';
import app from './App';

export default combineReducers( {
  auth,
  // config,
  app,
} );