import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Timer } from "../../helper/Timer";
import Web3 from "web3";
import getRpcUrl from "../../utilss/getRpcUrl";
import useWeb3 from "../../hookss/useWeb3";
import factoryabi from "../../abi/aqarfactory.json";
import erc20abi from "../../abi/erc20.json";
import vaultabi from "../../abi/aqarvault.json";
import Multicall from "../../helper/multicall/multicall";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { Link } from "react-router-dom";
import { CHAIN_ID } from "../../constants/constants";
import {
  factory,
  usdtAddress,
  wethAddress,
  usdcAddress,
  // wbnbAddress,
  wbtcAddress,
  // wxtzAddress,
  aqrAddress,
} from "../../constants/addresses.json";
import { FastField } from "formik";
import { ethers } from "ethers";
import WalletPopup from "../../components/Popup/Popup";
import WalletDetailsPopup from "../../components/WalletDetailsPopup/WalletDetailsPopup";

function Details ( props ) {
  const { blockChainData, property } = props.location.state;
  const [aqr, setaqr] = useState( "0" );
  const [usdt, setusdt] = useState( "1" );
  const [usdc, setusdc] = useState( "2" );
  const [matic, setmatic] = useState( "3" );
  const [wbtc, setwbtc] = useState( "4" );
  const [weth, setweth] = useState( "5" );
  const [wbnb, setwbnb] = useState( "6" );
  const [wxtz, setwxtz] = useState( "7" );

  const [aqrprice, setaqrprice] = useState();
  const [maticprice, setmaticprice] = useState();
  const [wbtcprice, setwbtcprice] = useState();
  const [wethprice, setwethprice] = useState();
  const [wbnbprice, setwbnbprice] = useState();
  const [wxtzprice, setwxtzprice] = useState();

  const [coinbalance, setcoinbalance] = useState();
  const [selectedcurrency, setselectedcurrency] = useState();

  const [option, setoption] = useState();
  const [approvalload, setapprovalload] = useState( false );
  const [amount, setamount] = useState( 0 );
  const [enteredamount, setenteredamount] = useState( 0 );
  const [buyoutamount, setbuyoutamount] = useState( 0 );
  const [vaultaddress, setvaultaddress] = useState( 0 );
  const [Allowance, setAllowance] = useState( {
    aqrAllowance: Number( 0 ),
    usdtAllowance: Number( 0 ),
    usdcAllowance: Number( 0 ),
    wbtcAllowance: Number( 0 ),
    wethAllowance: Number( 0 ),
  } );
  const [claimableBalance,setclaimablebalance]= useState(0);

  const [userid,setuserid]=useState()
  const params = useParams();
  const location = useLocation();
  const web3 = new Web3( getRpcUrl() );
  const { account, active, activate } = useWeb3React();

  const [apidata, setapidata] = useState( {} );
  const { auth } = useSelector( state => state );

  const userfetch = async() =>{
   
    setuserid(auth.user._id);
    // console.log( "User ID", auth.user._id );
  }
  
 
  
  const apicall = async () => {
    await axios
      .get(
        `https://token-api.aqarchain.io/admin/tokenized-property/${property.property.id}`
      )
      .then( ( res ) => {
        try {
          setapidata( res.data.data );
        } catch ( err ) {
          console.log( err, "error" );
        }
      } );

    await axios
      .get(
        "https://min-api.cryptocompare.com/data/pricemulti?fsyms=MATIC,BTC,ETH,BNB,XTZ&tsyms=USD"
      )
      .then( ( res ) => {
        try {
          setmaticprice( res.data.MATIC.USD );
          setwbtcprice( res.data.BTC.USD );
          setwethprice( res.data.ETH.USD );
          setwbnbprice( res.data.BNB.USD );
          setwxtzprice( res.data.XTZ.USD );
        } catch ( err ) {
          console.log( err, "error" );
        }
      } );
  };





  useEffect( () => {
    userfetch()
    loadAddressAndAllowance();
    apicall();
  }, [account,Allowance] );

  const [walletOpen, setWalletOpen] = useState( false );
  const closeModal = () => setWalletOpen( false );

  const [changePopupOpen, setChangePopupOpen] = useState( false );
  const closeModalChangePopup = () => setChangePopupOpen( false );
  const [avalibleBalanceState, setAvalibleBalanceState] = useState( 0 );

  const [diablevar, setdisablevar] = useState( false );


  const renderSwapButton = ( opt, vaultaddress ) => {
    if ( !account ) {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={
            active ? () => setChangePopupOpen( true ) : () => setWalletOpen( true )
          }
        >
          Unlock Wallet
        </button>
      );
    }
    if(!userid){
      <Link to="/login">
      <button
        className="btn btn-gradient w-100"
        type="button"
      >
        Log in
      </button>
    </Link>
    }

    if ( opt == "0" && Allowance.aqrAllow === 0 ) {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken( option, vaultaddress )}
        >
          {approvalload ? "Approving..." : "Approve AQR"}
        </button>
      );
    }
    if ( opt == "1" && Allowance.usdtAllow === 0 ) {
     // console.log( "Allowance.usdtAllowance", Allowance.usdtAllowance );
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken( option, vaultaddress )}
        >
          {approvalload ? "Approving..." : "Approve USDT"}
        </button>
      );
    }
    if ( opt == "2" && Allowance.usdcAllow === 0 ) {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken( option, vaultaddress )}
        >
          {approvalload ? "Approving..." : "Approve USDC"}
        </button>
      );
    }
    if ( opt == "4" && Allowance.wbtcAllow === 0 ) {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken( option, vaultaddress )}
        >
         {approvalload ? "Approving..." : "Approve WBTC"}
        </button>
      );
    }
    if ( opt == "5" && Allowance.wethAllow === 0 ) {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken( option, vaultaddress )}
        >
          {approvalload ? "Approving..." : "Approve WBTC"}
        </button>
      );
    }
    // if(auth.user._id)
    {return (
      <button
        className="btn btn-gradient w-100"
        type="button"
        onClick={() => buytoken( option, vaultaddress, enteredamount ,userid)}
      >
        Start Buying
      </button>
    );}
     
  };

  const renderBuyoutButton = ( opt, vaultaddress ) => {
    if ( !account ) {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={
            active ? () => setChangePopupOpen( true ) : () => setWalletOpen( true )
          }
        >
          Unlock Wallet
        </button>
      );
    }

    if ( opt == "0" && Allowance.aqrAllow === 0 ) {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken( option, vaultaddress )}
        >
          {approvalload ? "Approving..." : "Approve AQR"}
        </button>
      );
    }
    if ( opt == "1" && Allowance.usdtAllow === 0 ) {
      // console.log( "Allowance.usdtAllowance", Allowance.usdtAllowance );
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken( option, vaultaddress )}
        >
          Approve USDT
        </button>
      );
    }
    if ( opt == "2" && Allowance.usdcAllow === 0 ) {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken( option, vaultaddress )}
        >
          Approve USDC
        </button>
      );
    }
    if ( opt == "4" && Allowance.wbtcAllow === 0 ) {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken( option, vaultaddress )}
        >
          Approve WBTC
        </button>
      );
    }
    if ( opt == "5" && Allowance.wethAllow === 0 ) {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken( option, vaultaddress )}
        >
          Approve WETH
        </button>
      );
    }
    return (
      <button
        className="btn btn-gradient w-100"
        type="button"
        onClick={() => buytoken( option, vaultaddress, enteredamount )}
      >
        Start Buyout
      </button>
    );
  };

  const returnbal = async ( address ) => {
    const web3 = await new Web3( window.ethereum );
    const aqrcontract = new web3.eth.Contract( erc20abi.abi, address );
    if ( account ) {
      const bal = await aqrcontract.methods.balanceOf( account ).call();
      return bal;
    }
  };

  const loadAddressAndAllowance = async ( address ) => {
    const factorycalls = [
      {
        address: factory.address,
        name: "propIdTopropTokenId",
        params: [params.id], //location.pathname.slice(9)
      },
    ];
    const [propIdTopropTokenId] = await Multicall( factoryabi.abi, factorycalls );
    const vaultaddrescall = [
      {
        address: factory.address,
        name: "NftToToken",
        params: [parseInt( propIdTopropTokenId )],
      },
    ];

    const [b] = await Multicall( factoryabi.abi, vaultaddrescall );

    if ( b[0] && account ) {

      const claim = [{
        address: b[0],
          name: "claimableBalance",
          params: [account],
      }]
      const [claimable] = await Multicall( vaultabi.abi, claim );
     
      setclaimablebalance(new BigNumber( claimable[0] )
      .dividedBy( new BigNumber( 10 ).pow( 18 ) )
      .toNumber()) 
     

      const allowanceCalls = [
        {
          address: aqrAddress.address,
          name: "allowance",
          params: [account, b[0]],
        },
        {
          address: usdtAddress.address,
          name: "allowance",
          params: [account, b[0]],
        },
        {
          address: usdcAddress.address,
          name: "allowance",
          params: [account, b[0]],
        },
        {
          address: wbtcAddress.address,
          name: "allowance",
          params: [account, b[0]],
        },
        {
          address: wethAddress.address,
          name: "allowance",
          params: [account, b[0]],
        },
      ];

      var [aqrAllow, usdtAllow, usdcAllow, wbtcAllow, wethAllow] =
        await Multicall( erc20abi.abi, allowanceCalls );

      aqrAllow = new BigNumber( aqrAllow )
        .dividedBy( new BigNumber( 10 ).pow( 18 ) )
        .toNumber();
      usdtAllow = new BigNumber( usdtAllow )
        .dividedBy( new BigNumber( 10 ).pow( 6 ) )
        .toNumber();
      usdcAllow = new BigNumber( usdcAllow )
        .dividedBy( new BigNumber( 10 ).pow( 6 ) )
        .toNumber();
      wbtcAllow = new BigNumber( wbtcAllow )
        .dividedBy( new BigNumber( 10 ).pow( 8 ) )
        .toNumber();
      wethAllow = new BigNumber( wethAllow )
        .dividedBy( new BigNumber( 10 ).pow( 18 ) )
        .toNumber();

      // console.log(
      //   aqrAllow,
      //   usdtAllow,
      //   usdcAllow,
      //   wbtcAllow,
      //   wethAllow,
      //   "allowance"
      // );
      setAllowance( {
        aqrAllow,
        usdtAllow,
        usdcAllow,
        wbtcAllow,
        wethAllow,
      } );

      const vaultcontract = new web3.eth.Contract( vaultabi.abi, b[0] );

      const avaliblebalance = await vaultcontract.methods
        .availableBalance()
        .call();
      const avalibnebalnceinwei = web3.utils.fromWei( avaliblebalance, "ether" );

      setAvalibleBalanceState( avalibnebalnceinwei );
    }
    setvaultaddress( b );
  };

  const handleonchange = async (
    e,
    tokenSupply,
    tokenValue,
    maticprice,
    wbtcprice,
    wethprice
  ) => {
    setoption( e.target.value );
    setselectedcurrency( e.target.value );
    setenteredamount( 0 );
    setamount( 0 );
    if ( account ) {
      if ( e.target.value === "0" ) {
        setbuyoutamount( tokenSupply * tokenValue );
        const balance = await returnbal( aqrAddress.address ).then( ( res ) => {
          try {
            setcoinbalance(
              new BigNumber( parseInt( res ) )
                .dividedBy( new BigNumber( 10 ).pow( 18 ) )
                .toFixed( 2 )
            );
          } catch ( Err ) {
            setcoinbalance( 0 );
          }
        } );
      }
      if ( e.target.value === "1" ) {
        setbuyoutamount( tokenSupply * tokenValue );
        const balance = await returnbal( usdtAddress.address ).then( ( res ) => {
          try {
            setcoinbalance(
              new BigNumber( parseInt( res ) )
                .dividedBy( new BigNumber( 10 ).pow( 6 ) )
                .toFixed( 2 )
            );
          } catch ( Err ) {
            setcoinbalance( 0 );
          }
        } );
      }
      if ( e.target.value === "2" ) {
        setbuyoutamount( tokenSupply * tokenValue );
        const balance = await returnbal( usdcAddress.address ).then( ( res ) => {
          try {
            setcoinbalance(
              new BigNumber( parseInt( res ) )
                .dividedBy( new BigNumber( 10 ).pow( 6 ) )
                .toFixed( 2 )
            );
          } catch ( Err ) {
            setcoinbalance( 0 );
          }
        } );
      }
      if ( e.target.value === "3" ) {
        setbuyoutamount( ( tokenSupply * tokenValue ) / maticprice );
        const maticbal = await web3.eth.getBalance( account );

        try {
          setcoinbalance(
            new BigNumber( parseInt( maticbal ) )
              .dividedBy( new BigNumber( 10 ).pow( 18 ) )
              .toFixed( 2 )
          );
        } catch ( Err ) {
          setcoinbalance( 0 );
        }
      }
      if ( e.target.value === "4" ) {
        setbuyoutamount( ( tokenSupply * tokenValue ) / wbtcprice );
        const balance = await returnbal( wbtcAddress.address ).then( ( res ) => {
          try {
            setcoinbalance(
              new BigNumber( parseInt( res ) )
                .dividedBy( new BigNumber( 10 ).pow( 8 ) )
                .toFixed( 5 )
            );
          } catch ( Err ) {
            setcoinbalance( 0 );
          }
        } );
      }
      if ( e.target.value === "5" ) {
        setbuyoutamount( ( tokenSupply * tokenValue ) / wethprice );
        const balance = await returnbal( wethAddress.address ).then( ( res ) => {
          try {
            setcoinbalance(
              new BigNumber( parseInt( res ) )
                .dividedBy( new BigNumber( 10 ).pow( 18 ) )
                .toFixed( 4 )
            );
          } catch ( Err ) {
            setcoinbalance( 0 );
          }
        } );
      }
    }
  };

  const handleamountchange = async ( e, avalibleBalanceState ) => {
    setenteredamount( e.target.value );
    if ( e.target.value > 0 ) {
      if ( option === "0" ) {
        setamount( ( e.target.value * aqrprice ).toFixed( 2 ) );
        if (
          avalibleBalanceState <
          ( e.target.value / blockChainData[1] ) * aqrprice
        ) {
          setdisablevar( true );
        }

        if (
          avalibleBalanceState >=
          ( e.target.value / blockChainData[1] ) * aqrprice
        ) {
          setdisablevar( false );
        }
      }
      if ( option === "1" ) {
        setamount( e.target.value );
        // console.log( e.target.value );
        // console.log( avalibleBalanceState );
        // console.log(
        //   parseFloat( avalibleBalanceState ) < parseFloat( e.target.value )
        // );
        if ( avalibleBalanceState < e.target.value / blockChainData[1] ) {
          console.log( "true" );
          setdisablevar( true );
        }

        if ( avalibleBalanceState >= e.target.value / blockChainData[1] ) {
          setdisablevar( false );
        }
      }
      if ( option === "2" ) {
        setamount( e.target.value );
        if ( avalibleBalanceState < e.target.value / blockChainData[1] ) {
          setdisablevar( true );
        }

        if ( avalibleBalanceState >= e.target.value / blockChainData[1] ) {
          setdisablevar( false );
        }
      }
      if ( option === "3" ) {
        setamount( ( e.target.value * maticprice ).toFixed( 2 ) );
        if (
          avalibleBalanceState <
          ( e.target.value / blockChainData[1] ) * maticprice
        ) {
          setdisablevar( true );
        }

        if (
          avalibleBalanceState >=
          ( e.target.value / blockChainData[1] ) * maticprice
        ) {
          setdisablevar( false );
        }
      }
      if ( option === "4" ) {
        setamount( ( e.target.value * wbtcprice ).toFixed( 2 ) );
        if (
          avalibleBalanceState <
          ( e.target.value / blockChainData[1] ) * wbtcprice
        ) {
          setdisablevar( true );
        }

        if (
          avalibleBalanceState >=
          ( e.target.value / blockChainData[1] ) * wbtcprice
        ) {
          setdisablevar( false );
        }
      }
      if ( option === "5" ) {
        setamount( ( e.target.value * wethprice ).toFixed( 2 ) );
        if (
          avalibleBalanceState <
          ( e.target.value / blockChainData[1] ) * wethprice
        ) {
          setdisablevar( true );
        }

        if (
          avalibleBalanceState >=
          ( e.target.value / blockChainData[1] ) * wethprice
        ) {
          setdisablevar( false );
        }
      }
    }

  //  console.log( avalibleBalanceState );
  };

  const approvetoken = async ( opt, add ) => {
  //  console.log( ethers.constants.MaxUint256 );
    const web3 = await new Web3( window.ethereum );
    const aqrcontract = new web3.eth.Contract( erc20abi.abi, aqrAddress.address );
    const usdtcontract = new web3.eth.Contract(
      erc20abi.abi,
      usdtAddress.address
    );
    const usdccontract = new web3.eth.Contract(
      erc20abi.abi,
      usdcAddress.address
    );
    const wbtccontract = new web3.eth.Contract(
      erc20abi.abi,
      wbtcAddress.address
    );
    const wethcontract = new web3.eth.Contract(
      erc20abi.abi,
      wethAddress.addresss
    );
    if ( account ) {
      if ( opt === "0" ) {
        setapprovalload( true );
        const approve = await aqrcontract.methods
          .approve( add[0], ethers.constants.MaxUint256 )
          .send( { from: account } );
        setapprovalload( false );
      }
      if ( opt === "1" ) {
        setapprovalload( true );
        const approve = await usdtcontract.methods
          .approve( add[0], ethers.constants.MaxUint256 )
          .send( { from: account } );
        setapprovalload( false );
      }
      if ( opt === "2" ) {
        setapprovalload( true );
        const approve = await usdccontract.methods
          .approve( add[0], ethers.constants.MaxUint256 )
          .send( { from: account } );
        setapprovalload( false );
      }
      if ( opt === "4" ) {
        setapprovalload( true );
        const approve = await wbtccontract.methods
          .approve( add[0], ethers.constants.MaxUint256 )
          .send( { from: account } );
        setapprovalload( false );
      }
      if ( opt === "5" ) {
        setapprovalload( true );
        const approve = await wethcontract.methods
          .approve( add[0], ethers.constants.MaxUint256 )
          .send( { from: account } );
        setapprovalload( false );
      }
    }
  };
  const buytoken = async ( opt, add, amount,userid) => {
    const web3 = await new Web3( window.ethereum );
    let amountInWei = await web3.utils.toWei( amount.toString() );
    let amountInMwei = await web3.utils.toWei( amount.toString(), "mwei" );
    const vaultcontract = new web3.eth.Contract( vaultabi.abi, add[0] );

  //  console.log( amountInMwei * 100, "in mwei" );

    if ( account ) {
      if ( opt === "0" ) {
        const bal = await vaultcontract.methods
          .buyFromwhiteListCrypto(
            aqrAddress.address,
            amountInWei.toString(),
            userid
          )
          .send( { from: account } );
      }
      if ( opt === "1" ) {
        const bal = await vaultcontract.methods
          .buyTokenWithStableCoin(
            usdtAddress.address,
            amountInMwei.toString(),
            userid
          )
          .send( { from: account } );
      }
      if ( opt === "2" ) {
        const bal = await vaultcontract.methods
          .buyTokenWithStableCoin(
            usdcAddress.address,
            amountInMwei.toString(),
            userid
          )
          .send( { from: account } );
      }
      if ( opt === "3" ) {
        const bal = await vaultcontract.methods
          .buyFromMatic( amountInWei.toString(),userid )
          .send( { from: account, value: amountInWei } );
      }
      if ( opt === "4" ) {
        const bal = await vaultcontract.methods
          .buyFromBtc( ( amountInMwei * 100 ).toString(),userid )
          .send( { from: account } );
      }
      if ( opt === "5" ) {
        const bal = await vaultcontract.methods
          .buyFromwhiteListCrypto(
            wethAddress.address,
            amountInMwei.toString(),
            userid
          )
          .send( { from: account } );
      }
     
    }
  };
  return (
    <>
      {walletOpen && (
        <WalletPopup
          walletOpen={walletOpen}
          setWalletOpen={true}
          closeModal={closeModal}
        />
      )}
      {changePopupOpen && (
        <WalletDetailsPopup
          setWalletOpen={setWalletOpen}
          changePopupOpen={changePopupOpen}
          setChangePopupOpen={setChangePopupOpen}
          closeModal={closeModalChangePopup}
        />
      )}
      <section id="property-detail-header">
        <div className="container-lg pt-2">
          <div className="row">
            <div className="col-lg-8 text-center text-lg-left">
              <div className="row">
                <div className="col-lg-8 text-center text-lg-left">
                  <p className="mt-3 mb-0">
                    Property ID:{" "}
                    <span className="font-weight-bold">
                      {property.property.id}
                    </span>
                  </p>
                  <h1 className="font-weight-bold mb-3">{property.propertyTitle}</h1>
                </div>
                <div className="col-lg-4 text-center text-lg-right">
                  <div className="show-inline-container mt-md-4 mb-sm-4">
                    <div className="show-inline mt-2 mb-sm-2">
                      <a href="#" className="text-secondary">
                        <i
                          className="fa fa-3x fa-thumbs-o-up"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                    <div className="show-inline mt-2 mb-sm-2 ml-md-5">
                      <a href="#" className="text-secondary">
                        <i
                          className="fa fa-3x fa-bookmark-o"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 text-center text-lg-left">
                {/* <p class="mt-3 mb-0">Property ID: <span class="font-weight-bold">AQ96034</span></p>
                  <h1 class="font-weight-bold mb-3">Bejur Iftag Villa</h1> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="property-detail-hero">
        <div className="container-lg">
          <div className="row">
            <div className="col-lg-8 text-center">
              <div id="property-detail-hero-image" className="mb-4 mb-lg-0">
                <a
                  href="https://placehold.jp/48/1e282e/fae439/1200x900.jpg?text=Property%20Hero"
                  data-fancybox="property-detail-hero-gallery"
                  data-caption="Property Hero"
                >
                  <img
                    src={`https://token-api.aqarchain.io/${property.property.cover}`}
                    alt=""
                  />
                </a>
                <div id="property-detail-hero-image-badge">
                  <img
                    src="/public/images/aqarchain-verified.svg"
                    alt=""
                    className="mr-2"
                  />{" "}
                  Aqarverified
                </div>
              </div>
              <div
                id="property-detail-hero-gallery"
                style={{ display: "none" }}
              >
                <div id="property-detail-hero-gallery-images">
                  <a
                    className="property-detail-hero-gallery-image"
                    href="https://placehold.jp/48/1e282e/fae439/1200x900.jpg?text=Property%20Detail"
                    data-fancybox="property-detail-hero-gallery"
                    data-caption="Property Detail #1"
                  >
                    <img
                      src="https://placehold.jp/72/1e282e/fae439/1200x900.jpg?text=Property%20Detail"
                      alt=""
                    />
                  </a>
                  <a
                    className="property-detail-hero-gallery-image"
                    href="https://placehold.jp/48/1e282e/fae439/1200x900.jpg?text=Property%20Detail"
                    data-fancybox="property-detail-hero-gallery"
                    data-caption="Property Detail #2"
                  >
                    <img
                      src="https://placehold.jp/72/1e282e/fae439/1200x900.jpg?text=Property%20Detail"
                      alt=""
                    />
                  </a>
                  <a
                    className="property-detail-hero-gallery-image"
                    href="https://placehold.jp/48/1e282e/fae439/1200x900.jpg?text=Property%20Detail"
                    data-fancybox="property-detail-hero-gallery"
                    data-caption="Property Detail #3"
                  >
                    <img
                      src="https://placehold.jp/72/1e282e/fae439/1200x900.jpg?text=Property%20Detail"
                      alt=""
                    />
                  </a>
                  <a
                    className="property-detail-hero-gallery-image"
                    href="https://placehold.jp/48/1e282e/fae439/1200x900.jpg?text=Property%20Detail"
                    data-fancybox="property-detail-hero-gallery"
                    data-caption="Property Detail #4"
                  >
                    <img
                      src="https://placehold.jp/72/1e282e/fae439/1200x900.jpg?text=Property%20Detail"
                      alt=""
                    />
                  </a>
                  <a
                    className="property-detail-hero-gallery-image"
                    href="https://placehold.jp/48/1e282e/fae439/1200x900.jpg?text=Property%20Detail"
                    data-fancybox="property-detail-hero-gallery"
                    data-caption="Property Detail #5"
                  >
                    <img
                      src="https://placehold.jp/72/1e282e/fae439/1200x900.jpg?text=Property%20Detail"
                      alt=""
                    />
                  </a>
                  <a
                    className="property-detail-hero-gallery-image"
                    href="https://placehold.jp/48/1e282e/fae439/1200x900.jpg?text=Property%20Detail"
                    data-fancybox="property-detail-hero-gallery"
                    data-caption="Property Detail #6"
                  >
                    <img
                      src="https://placehold.jp/72/1e282e/fae439/1200x900.jpg?text=Property%20Detail"
                      alt=""
                    />
                  </a>
                </div>
                <div
                  id="property-detail-hero-gallery-find-more"
                  data-fancybox-trigger="property-detail-hero-gallery"
                >
                  <h2 className="text-white font-weight-bold mb-0">
                    + Find More
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-lg-4 text-center">
              <div id="property-details" className="mb-3">
                <div className="discover-card text-lg-left text-center">
                  <div className="pt-4">
                    <p>
                      <span className="green-discount font-weight-bold mr-2">
                        {blockChainData[6] / 10}% Discount
                      </span>{" "}
                      <span className="ml-2 font-weight-bold">
                        Via AQR Token{" "}
                      </span>
                      <img src="/public/images/AQR-Token.svg" />
                    </p>
                    {/* <img class="card-img-top discover-card-image" style="width: 100% !important;" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap"> */}
                    <div className="card-body">
                      {/* <div class="row">
                                      <div class="col-12">
                                       <h3 class="discover-card-title font-weight-bold">Luxury Villa in Dubai LOREM   </h3>
                                      </div>
                                   </div> */}
                      <div className="row">
                        <table
                          id="discover-table"
                          className="discover-table col-12"
                        >
                          <tbody>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">
                                <span>Token Supply</span>
                              </td>
                              <td className="font-weight-bold discover-card-content">
                                <span className="font-normal discover-table-left mr-2 ml-2">
                                  {" "}
                                  :{" "}
                                </span>
                                <span className="text-secondary">
                                  {blockChainData[0]}&nbsp;
                                  {blockChainData[3]}
                                </span>
                              </td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">
                                <span>Token Value</span>
                              </td>
                              <td className="font-weight-bold">
                                <span className="font-normal discover-table-left mr-2 ml-2">
                                  {" "}
                                  :{" "}
                                </span>
                                <span className="text-secondary">
                                  {`$ ${blockChainData[1]}`}
                                </span>
                                <br />
                              </td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">
                                <span>Gross Yield </span>
                              </td>
                              <td className="font-weight-bold">
                                <span className="font-normal discover-table-left mr-2 ml-2">
                                  {" "}
                                  :{" "}
                                </span>
                                <span className="text-secondary">
                                {`$ ${property.AnnualRental.$numberDecimal}`} 
                                </span>
                              </td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">
                                <span>ROI</span>
                              </td>
                              <td className="font-weight-bold">
                                <span className="font-normal discover-table-left mr-2 ml-2">
                                  :
                                </span>{" "}
                                {property.ROI.$numberDecimal} %
                                {/* <span className="text-secondary">
                                  {blockChainData.length !== 0 && (
                                    ( blockChainData[1] *
                                      ( ( property.AnnualRental.$numberDecimal / blockChainData[4] ) * 100 ) ).toFixed( 2 )
                                  )}
                                </span> */}
                              </td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">
                                <span>Claimable Balance</span>
                              </td>
                              <td className="font-weight-bold">
                                <span className="font-normal discover-table-left mr-2 ml-2">
                                  :
                                </span>{" "}
                                <span className="text-secondary">
                                  {claimableBalance}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock">
                              {/* <img src="/public/images/Timer-watch.svg" /> */}
                              {blockChainData[5] > 0 && (
                                <Timer unixtime={blockChainData[5]} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="property-details-buy-out-fractions" className="mt-4">
                  <ul className="nav nav-tabs buy-out-fractions-tabs row no-gutters mb-2">
                    {/* <li className="col-lg-6 col-12 buy-out-fractions-tabs-li active show">
                      <a
                        className="active show"
                        data-toggle="tab"
                        href="#buy-out"
                      >
                        Buy out
                      </a>
                    </li> */}
                    <li className="col-lg-12 col-12 buy-out-fractions-tabs-li">
                      <a className="active show" data-toggle="tab" href="#fractions">
                        Fractions
                      </a>
                    </li>
                  </ul>
                  <div className="row no-gutters mt-2 mb-2">
                    <div id="hr-line" className="col-12">
                      <hr
                        style={{
                          background:
                            "linear-gradient(89deg, #FBB042 0%, #FBB042 4%, #FDC010 39%, #FFDE11 100%)",
                          height: "5px",
                          border: "none",
                        }}
                      />
                    </div>
                  </div>
                  <div className="tab-content row mt-2 mb-2 p-4">
                    <div
                      id="buy-out"
                      className="col-12 tab-pane "
                    >
                      <div className="row d-flex align-items-center mt-2 mb-2">
                        <div className="col-7 text-left font-normal">
                          <span>ENTER BUYOUT AMOUNT</span>
                        </div>
                        <div className="col-5 text-right uc text-secondary font-semibold">
                          <span>
                            BALANCE: {coinbalance}{" "}
                            {selectedcurrency == 0
                              ? "AQR"
                              : selectedcurrency == 1
                                ? "USDT"
                                : selectedcurrency == 2
                                  ? "USDC"
                                  : selectedcurrency == 3
                                    ? "MATIC"
                                    : selectedcurrency == 4
                                      ? "WBTC"
                                      : selectedcurrency == 5
                                        ? "WETH"
                                        : selectedcurrency == 6
                                          ? "WBNB"
                                          : selectedcurrency == 7
                                            ? "WXTZ"
                                            : null}
                          </span>
                        </div>
                      </div>
                      <div className="property-details-buy-out-fractions row d-flex align-items-center">
                        <div className="col-8 text-left">
                          <p className="big text-secondary font-bold mb-0">
                            {buyoutamount.toFixed( 2 )}
                          </p>
                        </div>
                        <div className="col-4 p-3 text-right">
                          <select
                            id="ms-dropdown-selection"
                            className="form-control secondary-select"
                            style={{ backgroundColor: "rgb(255,222,17,0.1)" }}
                            onChange={( e ) => {
                              handleonchange(
                                e,
                                blockChainData[0],
                                blockChainData[1],
                                maticprice,
                                wbtcprice,
                                wethprice
                              );
                            }}
                          >
                            <option data-image="/public/images/crypto-icons/ETH.svg">
                              Available Currencies
                            </option>
                            <option
                              data-image="/public/images/crypto-icons/ETH.svg"
                              value={aqr}
                            >
                              AQR
                            </option>
                            <option
                              data-image="/public/images/crypto-icons/ADX.svg"
                              value={usdt}
                            >
                              USDT
                            </option>
                            <option
                              data-image="/public/images/crypto-icons/USDT.svg"
                              value={usdc}
                            >
                              USDC
                            </option>
                            <option
                              data-image="/public/images/crypto-icons/ETH.svg"
                              value={matic}
                            >
                              MATIC
                            </option>
                            <option
                              data-image="/public/images/crypto-icons/ADX.svg"
                              value={wbtc}
                            >
                              WBTC
                            </option>
                            <option
                              data-image="/public/images/crypto-icons/USDT.svg"
                              value={weth}
                            >
                              WETH
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center mt-2 mb-2">
                        <div className="col text-left font-normal">
                          <span>
                            ≈ $
                            {blockChainData[0] * blockChainData[1]}
                          </span>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center mt-5">
                        <div className="col text-center">
                          {userid ? (
                        
                            renderBuyoutButton( option, vaultaddress )
                          ) : (
                            <button
                              className="btn btn-gradient w-100"
                              type="button"
                            >
                              Log in
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div id="fractions" className="col-12 tab-pane fade in active show">
                      <div className="row d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div className=" text-left font-normal">
                          <span>Pay</span>
                        </div>
                        <div className=" text-right uc text-secondary font-semibold">
                          <span>
                            BALANCE: {coinbalance}{" "}
                            {selectedcurrency == 0
                              ? "AQR"
                              : selectedcurrency == 1
                                ? "USDT"
                                : selectedcurrency == 2
                                  ? "USDC"
                                  : selectedcurrency == 3
                                    ? "MATIC"
                                    : selectedcurrency == 4
                                      ? "WBTC"
                                      : selectedcurrency == 5
                                        ? "WETH"
                                        : selectedcurrency == 6
                                          ? "WBNB"
                                          : selectedcurrency == 7
                                            ? "WXTZ"
                                            : null}
                          </span>
                        </div>
                      </div>
                      <div className="property-details-buy-out-fractions row d-flex align-items-center">
                        <div className="col-8 text-left">
                          <input
                            style={{
                              border: "none",
                              width: "100%",
                              outline: "none",
                              padding: "1rem 15px",
                            }}
                            className="big text-secondary font-bold mb-0"
                            type="number"
                            value={enteredamount}
                            onChange={( e ) =>
                              handleamountchange( e, avalibleBalanceState )
                            }
                          />
                        </div>
                        <div className="col-4 p-3 text-right">
                          <select
                            id="ms-dropdown-selection"
                            className="form-control secondary-select"
                            onChange={( e ) => {
                              handleonchange( e, 0, 0, 0, 0, 0 );
                            }}
                            style={{ backgroundColor: "rgb(255,222,17,0.1)" }}
                          >
                            <option data-image="https://flagcdn.com/72x54/us.png">
                              Available currencies
                            </option>
                            <option
                              data-image="https://flagcdn.com/72x54/us.png"
                              value={aqr}
                              disabled
                            >
                              AQR {` Coming soon`}
                            </option>
                            <option
                              data-image="https://flagcdn.com/72x54/us.png"
                              value={usdt}
                            >
                              USDT
                            </option>
                            <option
                              data-image="https://flagcdn.com/72x54/us.png"
                              value={usdc}
                            >
                              USDC
                            </option>
                            <option
                              data-image="https://flagcdn.com/72x54/us.png"
                              value={matic}
                            >
                              MATIC
                            </option>
                            <option
                              data-image="https://flagcdn.com/72x54/ca.png"
                              value={wbtc}
                            >
                              WBTC
                            </option>
                            <option
                              data-image="https://flagcdn.com/72x54/us.png"
                              value={weth}
                            >
                              WETH
                            </option>
                            {/* <option
                              data-image="https://flagcdn.com/72x54/in.png"
                              value={wbnb}
                            >
                              WBNB
                            </option>
                            <option
                              data-image="https://flagcdn.com/72x54/ca.png"
                              value={wxtz}
                            >
                              WXTZ
                            </option> */}
                          </select>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center mt-4 mb-4">
                        <div className="col col-sm text-lg-left text-center font-normal">
                          For{" "}
                          <span className="text-secondary font-semibold">
                            100 USD
                          </span>{" "}
                          you will get{" "}
                          <span className="text-secondary font-semibold">
                            {(100 / blockChainData[1]).toFixed(4)}{" "}
                            {blockChainData[3]}
                          </span>
                        </div>
                      </div>
                      <div className="row d-flex align-items-center mt-2 mb-2">
                        <div className="col col-sm text-left font-normal">
                          <span>Recieve</span>
                        </div>
                        {/* <div className="col col-sm text-right uc text-secondary font-semibold"><span>BALANCE: 0 {blockChainData[3]}</span></div> */}
                      </div>
                      <div className="property-details-buy-out-fractions row d-flex align-items-center">
                        <div className="col-8 text-left">
                          <input
                            style={{
                              border: "none",
                              width: "100%",
                              outline: "none",
                              padding: "1rem 15px",
                            }}
                            className="big text-secondary font-bold mb-0"
                            value={(amount / blockChainData[1]).toFixed(4)}
                            disabled
                          />
                        </div>
                        <div className="col-4 p-3 text-right">
                          <select
                            id="ms-dropdown-selection"
                            className="form-control secondary-select"
                            style={{ backgroundColor: "rgb(255,222,17,0.1)" }}
                          >
                            <option
                              data-image="/public/images/crypto-icons/BTC.svg"
                              value="BTC"
                            >
                              {blockChainData[3]}
                            </option>
                          </select>
                        </div>
                      </div>
                      {option == 0 ? (
                        <div className="row d-flex align-items-center mt-2 mb-2">
                          <div className="col-4 text-left font-normal">
                            <span>Discount Applied</span>
                          </div>
                          <div className="col-6 pt-2 pb-2 text-center font-normal">
                            <hr style={{ height: "1px solid #efefef" }} />
                          </div>
                          <div className="col-2 text-right uc text-secondary font-semibold">
                            <span>$0.00</span>
                          </div>
                        </div>
                      ) : null}
                      {/* <div className="row d-flex align-items-center mt-2 mb-2">
                        <div className="col-4 text-left font-normal">
                          <span>Min. received</span>
                        </div>
                        <div className="col-6 pt-2 pb-2 text-center font-normal">
                          <hr style={{ height: "1px solid #efefef" }} />
                        </div>
                        <div className="col-2 text-right uc text-secondary font-semibold">
                          <span>0-----</span>
                        </div>
                      </div> */}
                      <div className="row d-flex align-items-center mt-5">
                        <div className="col text-center">
                          {userid? (            
                            diablevar ? (
                              <button
                                className="btn btn-gradient w-100"
                                type="button"
                                disabled={true}
                                onClick={() =>
                                  buytoken( option, vaultaddress, enteredamount )
                                }
                              >
                                Start Buying
                              </button>
                            ) : (
                              renderSwapButton( option, vaultaddress )
                            )
                          ): (
                           <Link  to="/login">
                              <button
                                className="btn btn-gradient w-100"
                                type="button"
                              >
                                Log in
                              </button>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters mt-2 mb-2">
                    <div id="hr-line" className="col-12">
                      <hr
                        style={{
                          background:
                            "linear-gradient(89deg, #FBB042 0%, #FBB042 4%, #FDC010 39%, #FFDE11 100%)",
                          height: "5px",
                          border: "none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="property-detail-meta-and-banner">
        <div id="property-detail-meta" className="container-lg">
          <div className="row">
            <div className="col-lg-8">
              {/* <div id="property-detail-meta-top">
                <div className="text-center d-block d-lg-flex justify-content-center">
                  <div
                    id="property-detail-meta-360_tour"
                    className="d-block d-lg-flex property-detail-meta align-items-center pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4"
                  >
                    <img src="/public/images/icon-property-detail-meta-360_tour.svg" />
                    <span className="ml-3">360° tour</span>
                  </div>
                  <div
                    id="property-detail-meta-video"
                    className="d-block d-lg-flex property-detail-meta align-items-center pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4"
                  >
                    <img src="/public/images/icon-property-detail-meta-video.svg" />
                    <span className="ml-3">Video</span>
                  </div>
                  <div
                    id="property-detail-meta-report"
                    className="d-block d-lg-flex property-detail-meta align-items-center"
                  >
                    <img src="/public/images/icon-property-detail-meta-report.svg" />
                    <span className="ml-3">Report</span>
                  </div>
                </div>
              </div> */}
              <div id="property-detail-meta-middle">
                <div className="text-center d-block text-lg-left d-lg-flex justify-content-center">
                  <div
                    id="property-detail-meta-price"
                    className="property-detail-meta pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-5 mr-lg-5"
                  >
                    <h2 className="mb-0">Price</h2>
                    <h1 className="mb-0 font-weight-bold">{`${property.property.currency} ${property.property.price}`}</h1>
                  </div>
                  <div
                    id="property-detail-meta-location"
                    className="property-detail-meta pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-5 mr-lg-5"
                  >
                    <h2 className="mb-0">Location</h2>
                    <h1 className="mb-0 font-weight-bold">{property.property.state},{property.property.city}</h1>
                  </div>
                  <div
                    id="property-detail-meta-type"
                    className="property-detail-meta"
                  >
                    <h2 className="mb-0">Type</h2>
                    <h1 className="mb-0 font-weight-bold">Villa</h1>
                  </div>
                </div>
              </div>
              <div id="property-detail-meta-bottom">
                <div className="text-center d-block d-lg-flex justify-content-center align-items-center">
                  <div
                    id="property-detail-meta-bedrooms"
                    className="d-block d-lg-flex property-detail-meta align-items-center pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4"
                  >
                    <img src="/public/images/icon-icon-property-detail-meta-bedrooms.svg" />
                    <span className="ml-3 font-weight-bold">{property.property.propertyFeature.number_of_beds} Bed</span>
                  </div>
                  <div
                    id="property-detail-meta-bathrooms"
                    className="d-block d-lg-flex property-detail-meta align-items-center pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4"
                  >
                    <img src="/public/images/icon-property-detail-meta-bathrooms.svg" />
                    <span className="ml-3 font-weight-bold">{property.property.propertyFeature.number_of_baths} Bath</span>
                  </div>
                  <div
                    id="property-detail-meta-area"
                    className="d-block d-lg-flex property-detail-meta align-items-center"
                  >
                    <img src="/public/images/icon-property-detail-meta-area.svg" />
                    <span className="ml-3 font-weight-bold">{property.property.propertyFeature.propertyArea} sq.ft.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="property-detail-banner" className="container-lg">
          <div className="row">
            <div className="col-lg-8 text-center">
              <div className="mt-5 mb-5">
                <a href="#">
                  <img
                    className="img-fluid"
                    src="/public/images/property-detail-banner.png"
                    alt="Best Deals in Dubai"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="property-detail-content">
        <div id="property-detail-tab-links-wrapper">
          <div id="property-detail-tab-links" className="container-lg">
            <div className="row">
              <div className="col-xl-8">
                <ul
                  className="nav nav-tabs nav-tabs nav-justified md-tabs property-detail-nav"
                  id="property-detail-tabs"
                >
                  <li className="nav-item mx-md-1 m-2">
                    <a
                      className="nav-link text-nowrap"
                      id="property-detail-tab-listed-by-tab"
                      // href="#property-detail-tab-listed-by"
                    >
                      Listed By
                    </a>
                  </li>
                  <li className="nav-item mx-md-1 m-2">
                    <a
                      className="nav-link"
                      id="property-detail-tab-description-tab"
                      // href="#property-detail-tab-description"
                    >
                      Description
                    </a>
                  </li>
                  {/* <li className="nav-item mx-md-1 m-2">
                    <a
                      className="nav-link"
                      id="property-detail-tab-amenities-tab"
                      href="#property-detail-tab-amenities"
                    >
                      Amenities
                    </a>
                  </li> */}
                  <li className="nav-item mx-md-1 m-2">
                    <a
                      className="nav-link"
                      id="property-detail-tab-location-tab"
                      // href="#property-detail-tab-location"
                    >
                      Location
                    </a>
                  </li>
                  {/* <li className="nav-item mx-md-1 m-2">
                    <a
                      className="nav-link"
                      id="property-detail-tab-reviews-tab"
                      href="#property-detail-tab-reviews"
                    >
                      Reviews
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-8">
              <div
                className="tab-content mt-3 mb-3"
                id="property-detail-tabs-content"
              >
                <div className="my-5" id="property-detail-tab-listed-by">
                  <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow">
                    <h2 className="font-weight-bold mb-4">Listed By</h2>
                    <p className>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Cum modi quo esse, natus explicabo quae numquam,
                      temporibus nemo ipsam dignissimos, beatae ex illo omnis
                      repudiandae distinctio nulla voluptate sequi fugiat?
                    </p>
                    <p className>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Cum modi quo esse, natus explicabo quae numquam,
                      temporibus nemo ipsam dignissimos, beatae ex illo omnis
                      repudiandae distinctio nulla voluptate sequi fugiat?
                    </p>
                    <p className="mb-0">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Cum modi quo esse, natus explicabo quae numquam,
                      temporibus nemo ipsam dignissimos, beatae ex illo omnis
                      repudiandae distinctio nulla voluptate sequi fugiat?
                    </p>
                  </div>
                </div>
                <div className="my-5" id="property-detail-tab-description">
                  <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow">
                    <h2 className="font-weight-bold mb-4">Description</h2>
                    
                    <p className="mb-0">
                      {property.property.description}
                    </p>
                  </div>
                </div>
                {/* <div className="my-5" id="property-detail-tab-amenities">
                  <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow bg-black">
                    <h2
                      className="font-weight-bold mb-4"
                      style={{ color: "#fff" }}
                    >
                      Amenities
                    </h2>
                    <div
                      id="property-detail-amenities"
                      className="text-center d-block d-lg-flex justify-content-around"
                    >
                      <div className="property-detail-amenity">
                        <img
                          src="/public/images/icon-amenities-elevator.svg"
                          alt="Elevator"
                        />
                        <p className="mb-3 mt-3 mb-lg-0 font-weight-bold">
                          Elevator
                        </p>
                      </div>
                      <div className="property-detail-amenity">
                        <img
                          src="/public/images/icon-amenities-laundry.svg"
                          alt="Laundry"
                        />
                        <p className="mb-3 mt-3 mb-lg-0 font-weight-bold">
                          Laundry
                        </p>
                      </div>
                      <div className="property-detail-amenity">
                        <img
                          src="/public/images/icon-amenities-parking.svg"
                          alt="Parking"
                        />
                        <p className="mb-3 mt-3 mb-lg-0 font-weight-bold">
                          Free Parking
                        </p>
                      </div>
                      <div className="property-detail-amenity">
                        <img
                          src="/public/images/icon-amenities-gym.svg"
                          alt="Gym"
                        />
                        <p className="mb-3 mt-3 mb-lg-0 font-weight-bold">
                          Gym
                        </p>
                      </div>
                      <div className="property-detail-amenity">
                        <img
                          src="/public/images/icon-amenities-central_ac.svg"
                          alt="Central AC"
                        />
                        <p className="mb-3 mt-3 mb-lg-0 font-weight-bold">
                          Central AC
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="my-5" id="property-detail-tab-location">
                  <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow">
                    <h2 className="font-weight-bold mb-4">Location</h2>
                    {/* <img class="img-fluid map-view" src="public/images/google-map-placeholder.png"> */}
                    <iframe
                      src="https://www.google.com/maps/d/embed?mid=1kbKceIQ_9WA5qYSCD8YrH4BGgx1pJZHZ&z=12"
                      width="100%"
                      height={480}
                      style={{ border: 0 }}
                    />
                  </div>
                </div>
                <div
                  className="my-5"
                  id="property-detail-tab-reviews"
                  aria-labelledby="property-detail-tab-reviews-tab"
                >
                  {/* <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow">
                    <h2 className="font-weight-bold mb-4">Reviews</h2>
                    <div id="property-detail-reviews">
                      <div className="property-detail-review d-block d-lg-flex pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4">
                        <div className="property-detail-review-image">
                          <img src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" />
                        </div>
                        <div className="property-detail-review-text mt-3 mt-lg-0 ml-lg-3">
                          <h3 className="font-weight-bold mb-0">
                            Rashid Musaafir
                          </h3>
                          <small className="text-grey font-weight-medium">
                            Reviewed In Dubai On 23 October 2020
                          </small>
                          <div className="d-flex property-rating my-2">
                            <div className="cursor-pointer">
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img src="/public/images/icon-star.svg" alt="" />
                              <img src="/public/images/icon-star.svg" alt="" />
                            </div>
                          </div>
                          <p className="mb-0">
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Cum modi quo esse, natus explicabo quae
                            numquam, temporibus nemo ipsam dignissimos, beatae
                            ex illo omnis.
                          </p>
                        </div>
                      </div>
                      <div className="property-detail-review d-block d-lg-flex pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4">
                        <div className="property-detail-review-image">
                          <img src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" />
                        </div>
                        <div className="property-detail-review-text mt-3 mt-lg-0 ml-lg-3">
                          <h3 className="font-weight-bold mb-0">
                            Rashid Musaafir
                          </h3>
                          <small className="text-grey font-weight-medium">
                            Reviewed In Dubai On 23 October 2020
                          </small>
                          <div className="d-flex property-rating my-2">
                            <div className="cursor-pointer">
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img src="/public/images/icon-star.svg" alt="" />
                              <img src="/public/images/icon-star.svg" alt="" />
                            </div>
                          </div>
                          <p className="mb-0">
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Cum modi quo esse, natus explicabo quae
                            numquam, temporibus nemo ipsam dignissimos, beatae
                            ex illo omnis.
                          </p>
                        </div>
                      </div>
                      <div className="property-detail-review d-block d-lg-flex pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4">
                        <div className="property-detail-review-image">
                          <img src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" />
                        </div>
                        <div className="property-detail-review-text mt-3 mt-lg-0 ml-lg-3">
                          <h3 className="font-weight-bold mb-0">
                            Rashid Musaafir
                          </h3>
                          <small className="text-grey font-weight-medium">
                            Reviewed In Dubai On 23 October 2020
                          </small>
                          <div className="d-flex property-rating my-2">
                            <div className="cursor-pointer">
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img src="/public/images/icon-star.svg" alt="" />
                              <img src="/public/images/icon-star.svg" alt="" />
                            </div>
                          </div>
                          <p className="mb-0">
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Cum modi quo esse, natus explicabo quae
                            numquam, temporibus nemo ipsam dignissimos, beatae
                            ex illo omnis.
                          </p>
                        </div>
                      </div>
                      <div className="property-detail-review d-block d-lg-flex pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4">
                        <div className="property-detail-review-image">
                          <img src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" />
                        </div>
                        <div className="property-detail-review-text mt-3 mt-lg-0 ml-lg-3">
                          <h3 className="font-weight-bold mb-0">
                            Rashid Musaafir
                          </h3>
                          <small className="text-grey font-weight-medium">
                            Reviewed In Dubai On 23 October 2020
                          </small>
                          <div className="d-flex property-rating my-2">
                            <div className="cursor-pointer">
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img src="/public/images/icon-star.svg" alt="" />
                              <img src="/public/images/icon-star.svg" alt="" />
                            </div>
                          </div>
                          <p className="mb-0">
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Cum modi quo esse, natus explicabo quae
                            numquam, temporibus nemo ipsam dignissimos, beatae
                            ex illo omnis.
                          </p>
                        </div>
                      </div>
                      <div className="property-detail-review d-block d-lg-flex pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4">
                        <div className="property-detail-review-image">
                          <img src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" />
                        </div>
                        <div className="property-detail-review-text mt-3 mt-lg-0 ml-lg-3">
                          <h3 className="font-weight-bold mb-0">
                            Rashid Musaafir
                          </h3>
                          <small className="text-grey font-weight-medium">
                            Reviewed In Dubai On 23 October 2020
                          </small>
                          <div className="d-flex property-rating my-2">
                            <div className="cursor-pointer">
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img
                                src="/public/images/icon-star-active.svg"
                                alt=""
                              />
                              <img src="/public/images/icon-star.svg" alt="" />
                              <img src="/public/images/icon-star.svg" alt="" />
                            </div>
                          </div>
                          <p className="mb-0">
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Cum modi quo esse, natus explicabo quae
                            numquam, temporibus nemo ipsam dignissimos, beatae
                            ex illo omnis.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-12 col-xs-12 col-sm-12 text-center">
              <div className="mb-3 mt-lg-5">
                <div className="text-center text-lg-left mt-2 p-2 has-box-shadow">
                  <div
                    className="row"
                    style={{ borderBottom: "1px solid #efefef" }}
                  >
                    {/* <div className="col-12">
                      <h3 className="p-3">
                        You may also{" "}
                        <span className="font-weight-bold text-secondary">
                          Like
                        </span>
                      </h3>
                    </div> */}
                  </div>
                  <div
                    className="owl-carousel p-5"
                    id="other-property-carousel"
                  >
                    <div className="item">
                      <div
                        className="discover-card has-box-shadow text-lg-left text-center"
                        style={{ border: "1px solid #efefef" }}
                      >
                        <div className="card-body">
                          <p>
                            <span className="green-discount font-weight-bold mr-2">
                              2% Discount
                            </span>{" "}
                            <span className="ml-2 font-weight-bold">
                              Via AQR Token{" "}
                            </span>
                            <img src="/public/images/AQR-Token.svg" />
                          </p>
                          <img
                            className="card-img-top discover-card-image"
                            style={{ width: "100% !important" }}
                            src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result"
                            alt="discover-cardimage cap"
                          />
                          <div className="card-body">
                            <div className="row">
                              <h3 className="discover-card-title font-weight-bold mb-3">
                                Luxury Villa in Dubai LOREM{" "}
                              </h3>
                            </div>
                            <div className="row">
                              <table
                                id="discover-table"
                                className="discover-table col-12"
                              >
                                <tbody>
                                  <tr className="discover-table-border">
                                    <td className="discover-table-left discover-card-content">
                                      Token Supply
                                    </td>
                                    <td className="font-weight-bold discover-card-content">
                                      <span className="font-normal discover-table-left mr-2 ml-2">
                                        {" "}
                                        :{" "}
                                      </span>
                                      <span className="text-secondary">
                                        10.5M Prop Token
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="discover-table-border">
                                    <td className="discover-table-left discover-token-value">
                                      Token Value
                                    </td>
                                    <td className="font-weight-bold">
                                      <span className="font-normal discover-table-left mr-2 ml-2">
                                        {" "}
                                        :{" "}
                                      </span>
                                      <span className="text-secondary">
                                        0.0001 Token Name
                                      </span>
                                      <br />
                                      <span className="discover-table-left mr-2 ml-4">
                                        = 3500$
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="discover-table-border">
                                    <td className="discover-table-left discover-card-content">
                                      Per Share Value
                                    </td>
                                    <td className="font-weight-bold">
                                      <span className="font-normal discover-table-left mr-2 ml-2">
                                        {" "}
                                        :{" "}
                                      </span>
                                      <span className="text-secondary">
                                        2600
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="discover-table-border discover-table-border-down">
                                    <td className="discover-table-left discover-card-content">
                                      ROI
                                    </td>
                                    <td className="font-weight-bold">
                                      <span className="font-normal discover-table-left mr-2 ml-2">
                                        :
                                      </span>{" "}
                                      <span className="text-secondary">
                                        $25,000
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="col-12 mt-4 text-center d-flex justify-content-center">
                                <div className="property-clock-wrap">
                                  <div className="text-secondary font-bold big property-clock">
                                    <img src="/public/images/Timer-watch.svg" />{" "}
                                    07D: 18H: 15:30 S
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="discover-card has-box-shadow text-lg-left text-center">
                        <div className="card-body">
                          <p>
                            <span className="green-discount font-weight-bold mr-2">
                              2% Discount
                            </span>{" "}
                            <span className="ml-2 font-weight-bold">
                              Via AQR Token{" "}
                            </span>
                            <img src="/public/images/AQR-Token.svg" />
                          </p>
                          <img
                            className="card-img-top discover-card-image"
                            style={{ width: "100% !important" }}
                            src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result"
                            alt="discover-cardimage cap"
                          />
                          <div className="card-body">
                            <div className="row">
                              <h3 className="discover-card-title font-weight-bold mb-3">
                                Luxury Villa in Dubai LOREM{" "}
                              </h3>
                            </div>
                            <div className="row">
                              <table
                                id="discover-table"
                                className="discover-table col-12"
                              >
                                <tbody>
                                  <tr className="discover-table-border">
                                    <td className="discover-table-left discover-card-content">
                                      Token Supply
                                    </td>
                                    <td className="font-weight-bold discover-card-content">
                                      <span className="font-normal discover-table-left mr-2 ml-2">
                                        {" "}
                                        :{" "}
                                      </span>
                                      <span className="text-secondary">
                                        10.5M Prop Token
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="discover-table-border">
                                    <td className="discover-table-left discover-token-value">
                                      Token Value
                                    </td>
                                    <td className="font-weight-bold">
                                      <span className="font-normal discover-table-left mr-2 ml-2">
                                        {" "}
                                        :{" "}
                                      </span>
                                      <span className="text-secondary">
                                        0.0001 Token Name
                                      </span>
                                      <br />
                                      <span className="discover-table-left mr-2 ml-4">
                                        = 3500$
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="discover-table-border">
                                    <td className="discover-table-left discover-card-content">
                                      Per Share Value
                                    </td>
                                    <td className="font-weight-bold">
                                      <span className="font-normal discover-table-left mr-2 ml-2">
                                        {" "}
                                        :{" "}
                                      </span>
                                      <span className="text-secondary">
                                        2600
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="discover-table-border discover-table-border-down">
                                    <td className="discover-table-left discover-card-content">
                                      ROI
                                    </td>
                                    <td className="font-weight-bold">
                                      <span className="font-normal discover-table-left mr-2 ml-2">
                                        :
                                      </span>{" "}
                                      <span className="text-secondary">
                                        $25,000
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="col-12 mt-4 text-center d-flex justify-content-center">
                                <div className="property-clock-wrap">
                                  <div className="text-secondary font-bold big property-clock">
                                    <img src="/public/images/Timer-watch.svg" />{" "}
                                    07D: 18H: 15:30 S
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="discover-card has-box-shadow text-lg-left text-center">
                        <div className="card-body">
                          <p>
                            <span className="green-discount font-weight-bold mr-2">
                              2% Discount
                            </span>{" "}
                            <span className="ml-2 font-weight-bold">
                              Via AQR Token{" "}
                            </span>
                            <img src="/public/images/AQR-Token.svg" />
                          </p>
                          <img
                            className="card-img-top discover-card-image"
                            style={{ width: "100% !important" }}
                            src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result"
                            alt="discover-cardimage cap"
                          />
                          <div className="card-body">
                            <div className="row">
                              <h3 className="discover-card-title font-weight-bold mb-3">
                                Luxury Villa in Dubai LOREM{" "}
                              </h3>
                            </div>
                            <div className="row">
                              <table
                                id="discover-table"
                                className="discover-table col-12"
                              >
                                <tbody>
                                  <tr className="discover-table-border">
                                    <td className="discover-table-left discover-card-content">
                                      Token Supply
                                    </td>
                                    <td className="font-weight-bold discover-card-content">
                                      <span className="font-normal discover-table-left mr-2 ml-2">
                                        {" "}
                                        :{" "}
                                      </span>
                                      <span className="text-secondary">
                                        10.5M Prop Token
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="discover-table-border">
                                    <td className="discover-table-left discover-token-value">
                                      Token Value
                                    </td>
                                    <td className="font-weight-bold">
                                      <span className="font-normal discover-table-left mr-2 ml-2">
                                        {" "}
                                        :{" "}
                                      </span>
                                      <span className="text-secondary">
                                        0.0001 Token Name
                                      </span>
                                      <br />
                                      <span className="discover-table-left mr-2 ml-4">
                                        = 3500$
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="discover-table-border">
                                    <td className="discover-table-left discover-card-content">
                                      Per Share Value
                                    </td>
                                    <td className="font-weight-bold">
                                      <span className="font-normal discover-table-left mr-2 ml-2">
                                        {" "}
                                        :{" "}
                                      </span>
                                      <span className="text-secondary">
                                        2600
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="discover-table-border discover-table-border-down">
                                    <td className="discover-table-left discover-card-content">
                                      ROI
                                    </td>
                                    <td className="font-weight-bold">
                                      <span className="font-normal discover-table-left mr-2 ml-2">
                                        :
                                      </span>{" "}
                                      <span className="text-secondary">
                                        $25,000
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="col-12 mt-4 text-center d-flex justify-content-center">
                                <div className="property-clock-wrap">
                                  <div className="text-secondary font-bold big property-clock">
                                    <img src="/public/images/Timer-watch.svg" />{" "}
                                    07D: 18H: 15:30 S
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div class="text-center">
                               <button class="btn btn-gradient btn-rounded mt-4">View All</button>
                            </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Details;
