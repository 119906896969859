import React from 'react';

import { useSelector } from 'react-redux'

const KYC = () => {

  const user = {
    kyc_status:"PENDING"
  }
  return (
    <>
      {/* <!--KYC CONFIRMED--> */}
        {user.kyc_status === 'PENDING' && (
          <>
            <div
              className="text-center text-lg-left py-lg-4 px-lg-5 p-3 has-box-shadow has-border-radius kyc-status-pending my-4"
              style={{
                backgroundImage: `url("/images/kyc-status-pending-bg.svg")`,
              }}>
              <div className="row d-lg-flex align-items-center">
                <div className="col-lg-4 col-12">
                  <h3 className="font-weight-bold mb-3 mb-lg-0">KYC Status</h3>
                </div>
                <div className="col-lg-8 col-12 d-flex justify-content-end align-items-center">
                  <img
                    alt=""
                    className="icon-24 mr-2"
                    src={require("../../../assets/images/kyc-pending-icon-black.svg").default}
                  />
                  <h5 className="font-weight-bold text-dark mb-0">Pending</h5>
                </div>
              </div>
            </div>
            <div className="text-center text-lg-left py-lg-3 px-lg-5 p-3 has-box-shadow has-border-radius my-4">
              <div className="row d-lg-flex align-items-center">
                <div className="col-lg-4 col-12">
                  <h3 className="font-weight-medium mb-3 mb-lg-0">
                    Identity Verification
                  </h3>
                </div>
                <div className="col-lg-4 offset-lg-2 col-12 text-right">
                  <p className="text-warning font-weight-bold mb-0">Pending</p>
                </div>
                <div className="col-lg-2 col-12">
                  <button className="btn btn-blue btn-sm btn-rounded w-100">
                    View
                  </button>
                </div>
              </div>
            </div>
            <div className="text-center text-lg-left py-lg-3 px-lg-5 p-3 has-box-shadow has-border-radius my-4">
              <div className="row d-lg-flex align-items-center">
                <div className="col-lg-4 col-12">
                  <h3 className="font-weight-medium mb-3 mb-lg-0">
                    Source of Wealth
                  </h3>
                </div>
                <div className="col-lg-4 offset-lg-2 col-12 text-right">
                  <p className="text-warning font-weight-bold mb-0">Pending</p>
                </div>
                <div className="col-lg-2 col-12">
                  <button className="btn btn-blue btn-sm btn-rounded w-100">
                    View
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {user.kyc_status === 'APPROVED' && (
          <>
            <div className="text-center text-lg-left py-lg-4 px-lg-5 p-3 has-box-shadow has-border-radius kyc-status-confirmed my-4">
              <div className="row d-lg-flex align-items-center">
                <div className="col-lg-4 col-12">
                  <h3 className="font-weight-bold mb-3 mb-lg-0">KYC Status</h3>
                </div>
                <div className="col-lg-8 col-12 d-flex justify-content-end align-items-center">
                  <img
                    alt=""
                    className="icon-24 mr-2"
                    src={require("../../../assets/images/kyc-confirmation-icon-white.svg").default}
                  />
                  <h5 className="font-weight-bold text-dark mb-0">Confirmed</h5>
                </div>
              </div>
            </div>
            <div class="text-center text-lg-left py-lg-3 px-lg-5 p-3 has-box-shadow has-border-radius my-4">
              <div class="row d-lg-flex align-items-center">
                <div class="col-lg-4 col-12">
                  <h3 class="font-weight-medium mb-3 mb-lg-0">
                    Identity Verification
                  </h3>
                </div>
                <div class="col-lg-4 offset-lg-2 col-12 text-right">
                  <p class="text-success font-weight-bold mb-0">Confirmed</p>
                </div>
                <div class="col-lg-2 col-12">
                  <button class="btn btn-blue btn-sm btn-rounded w-100">
                    View
                  </button>
                </div>
              </div>
            </div>
            <div class="text-center text-lg-left py-lg-3 px-lg-5 p-3 has-box-shadow has-border-radius my-4">
              <div class="row d-lg-flex align-items-center">
                <div class="col-lg-4 col-12">
                  <h3 class="font-weight-medium mb-3 mb-lg-0">
                    Source of Wealth
                  </h3>
                </div>
                <div class="col-lg-4 offset-lg-2 col-12 text-right">
                  <p class="text-success font-weight-bold mb-0">Confirmed</p>
                </div>
                <div class="col-lg-2 col-12">
                  <button class="btn btn-blue btn-sm btn-rounded w-100">
                    View
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {user.kyc_status === 'REJECTED' && (
          <>
            <div className="text-center text-lg-left py-lg-4 px-lg-5 p-3 has-box-shadow has-border-radius kyc-status-confirmed my-4">
              <div className="row d-lg-flex align-items-center">
                <div className="col-lg-4 col-12">
                  <h3 className="font-weight-bold mb-3 mb-lg-0">KYC Status</h3>
                </div>
                <div className="col-lg-8 col-12 d-flex justify-content-end align-items-center">
                  <img
                    alt=""
                    className="icon-24 mr-2"
                    src={require("../../../assets/images/kyc-confirmation-icon-white.svg").default}
                  />
                  <h5 className="font-weight-bold text-dark mb-0">Rejected</h5>
                </div>
              </div>
            </div>
            <div class="text-center text-lg-left py-lg-3 px-lg-5 p-3 has-box-shadow has-border-radius my-4">
              <div class="row d-lg-flex align-items-center">
                <div class="col-lg-4 col-12">
                  <h3 class="font-weight-medium mb-3 mb-lg-0">
                    Identity Verification
                  </h3>
                </div>
                <div class="col-lg-4 offset-lg-2 col-12 text-right">
                  <p class="text-success font-weight-bold mb-0">Confirmed</p>
                </div>
                <div class="col-lg-2 col-12">
                  <button class="btn btn-blue btn-sm btn-rounded w-100">
                    View
                  </button>
                </div>
              </div>
            </div>
            <div class="text-center text-lg-left py-lg-3 px-lg-5 p-3 has-box-shadow has-border-radius my-4">
              <div class="row d-lg-flex align-items-center">
                <div class="col-lg-4 col-12">
                  <h3 class="font-weight-medium mb-3 mb-lg-0">
                    Source of Wealth
                  </h3>
                </div>
                <div class="col-lg-4 offset-lg-2 col-12 text-right">
                  <p class="text-success font-weight-bold mb-0">Confirmed</p>
                </div>
                <div class="col-lg-2 col-12">
                  <button class="btn btn-blue btn-sm btn-rounded w-100">
                    View
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default KYC;
