import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import getStoredState from "redux-persist/es/getStoredState";
import ReactPaginate from "react-paginate";
import "./paginate.css";
import { ethers, Contract } from "ethers";
import BigNumber from "bignumber.js";
import factoryabi from "../../abi/aqarfactory.json";
import vaultabi from "../../abi/aqarvault.json";
import Multicall from "../../helper/multicall/multicall";
import { Link } from "react-router-dom";
import { Timer } from "../../helper/Timer";
import {
  factory,
  usdtAddress,
  wethAddress,
  usdcAddress,
  // wbnbAddress,
  wbtcAddress,
  // wxtzAddress,
  aqrAddress,
} from "../../constants/addresses.json";
import Loading from "../Loading";
import { useDispatch } from "react-redux";
import { getTotenizedProperty } from "../../Redux/action/App";
import IsLoadingHOC from "../IsLoadingHOC/index";
import SearchBar from "../SearchBar";

function Discover2 ( props ) {
  const { setLoading } = props
  const [showfilter, setshowfilter] = useState( false );
  const [loader, setLoader] = useState( true );

  const [state, setstate] = useState();
  const [country, setcountry] = useState();
  const [city, setcity] = useState();
  const [proptype, setproptype] = useState();

  const [data, setdata] = useState( [{}] );
  const [propblockdata, setpropblockdata] = useState( [] );

  const [allProperty, setAllProperty] = useState( [] )

  //property pagination
  const [pagenumber, setpagenumber] = useState( 0 );
  const propPerPage = 16;
  const pagevisited = propPerPage * pagenumber;
  const pagecount = Math.ceil( data.length / propPerPage );
  const changePage = ( { selected } ) => {
    setpagenumber( selected );
  };

  //getting each user balance
  const gettingPropDataFromBlockchain = async ( tokens ) => {

    const promises = tokens.map( async ( token ) => {
      const factorycalls = [
        {
          address: factory.address,
          name: "propIdTopropTokenId",
          params: [token.property.id],
        },
      ];
      const [a] = await Multicall( factoryabi.abi, factorycalls );
      
      const vaultaddrescall = [
        {
          address: factory.address,
          name: "NftToToken",
          params: [parseInt( a )],
        },
      ];

      const [b] = await Multicall( factoryabi.abi, vaultaddrescall );
      
     // console.log("fuckingno",a,b,token.property.id)
      const vaultcalls = [
        {
          address: b.toString(),
          name: "totalSupply",
        },
        {
          address: b.toString(),
          name: "tokenPrice",
        },
        {
          address: b.toString(),
          name: "name",
        },
        {
          address: b.toString(),
          name: "symbol",
        },
        {
          address: b.toString(),
          name: "ListPrice",
        },
        {
          address: b.toString(),
          name: "endtime",
        },
        {
          address: b.toString(),
          name: "fee",
        },
      ];
      const [c, d, e, f, listprice, endtime, fee] = await Multicall(
        vaultabi.abi,
        vaultcalls
      );

      var total = [
        new BigNumber( parseInt( c ) )
          .dividedBy( new BigNumber( 10 ).pow( "18" ) )
          .toNumber()
          .toFixed( 2 ),
        new BigNumber( parseInt( d ) )
          .dividedBy( new BigNumber( 10 ).pow( "18" ) )
          .toNumber()
          .toFixed( 2 ),
        e,
        f,
        new BigNumber( parseInt( listprice ) )
          .dividedBy( new BigNumber( 10 ).pow( "18" ) )
          .toNumber()
          .toFixed( 2 ),
        parseInt( endtime ),
        parseInt( fee )
      ];
      return total;
    } );

    const resp = await Promise.all( promises );
    setpropblockdata( resp );
  };


  const dispatch = useDispatch()


  const loadProperty = async () => {
   

    setLoading( true )
    const body = {
      limit: "1000",
      filter: {
        propertyTitle: ""
      }
    };
    await dispatch( getTotenizedProperty( body ) )
      .then(
        response => {
          setAllProperty( response.data )
          setLoading( false )
          gettingPropDataFromBlockchain( response.data )
        }
        , error => {
          console.log( "response", error );
        }
      )
      .catch(
        error => {
          console.log( "response", error );
        }
      )
  }

  useEffect( () => {
    loadProperty()
  }, [] );


  return (
    <div>
      <div>
        <section id="discover-section_1" >
          <div className="container-lg">
            <div className="row align-items-center">
              <div id="discover-section_1-form" className="col-lg-5">
                <div id="discover-form">
                  <div id="discover-form-top">
                    <SearchBar setAllProperty={setAllProperty} gettingPropDataFromBlockchain={gettingPropDataFromBlockchain} setLoading={setLoading} />
                  </div>
                </div>
              </div>
              {/* <div
                id="discover-filter_options"
                className="col-lg-2 col-6 offset-lg-3"
              >
                <div
                  id="discover-sort_by"
                  className="discover-filter_option justify-content-end"
                >
                  <a
                    className="alt-white text-decoration-none"
                    href="/discover-properties2.html"
                  >
                    <span className="mr-3">Sort By</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      className="icon-discover-filter_options"
                    >
                      <title>Sort By</title>
                      <g
                        id="icon-discover-filter_options-sort_by-1"
                        data-name="Layer 1"
                      >
                        <g
                          id="icon-discover-filter_options-sort_by-2"
                          data-name="Layer 2"
                        >
                          <g
                            id="icon-discover-filter_options-sort_by-3"
                            data-name="Layer 3"
                          >
                            <path d="M14.18,21.11,9.23,26.06V1.15a1.16,1.16,0,0,0-2.31,0V26.06L2,21.11A1.15,1.15,0,1,0,.34,22.74l6.92,6.92a1.14,1.14,0,0,0,.82.34,1.12,1.12,0,0,0,.81-.34l6.93-6.92a1.15,1.15,0,0,0,0-1.63,1.16,1.16,0,0,0-1.64,0Z" />
                            <path d="M29.66,7.26,22.74.34a1.15,1.15,0,0,0-1.63,0L14.18,7.26a1.15,1.15,0,0,0,0,1.63,1.16,1.16,0,0,0,1.64,0l4.95-5V28.85a1.16,1.16,0,0,0,2.31,0V3.94l5,5a1.14,1.14,0,0,0,.82.34,1.12,1.12,0,0,0,.81-.34,1.15,1.15,0,0,0,0-1.63Z" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
              </div> */}
              {/* <div
                id="discover-filter_options"
                className="col-lg-2 col-6"
                onClick={() => setshowfilter( !showfilter )}
              >
                <div id="discover-filters" className="discover-filter_option">
                  <a className="alt-white text-decoration-none">
                    <span className="mr-3">Filter</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32.31 30"
                      className="icon-discover-filter_options"
                    >
                      <title>Filter</title>
                      <g
                        id="icon-discover-filter_options-filter-1"
                        data-name="Layer 1"
                      >
                        <g
                          id="icon-discover-filter_options-filter-2"
                          data-name="Layer 2"
                        >
                          <g
                            id="icon-discover-filter_options-filter-3"
                            data-name="Layer 3"
                          >
                            <path d="M28.85,11.54H3.46a1.16,1.16,0,0,1,0-2.31H28.85a1.16,1.16,0,0,1,0,2.31Z" />
                            <path d="M24.23,20.77H8.08a1.16,1.16,0,1,1,0-2.31H24.23a1.16,1.16,0,0,1,0,2.31Z" />
                            <path d="M19.62,30H12.69a1.16,1.16,0,0,1,0-2.31h6.93a1.16,1.16,0,0,1,0,2.31Z" />
                            <path d="M31.15,2.31h-30A1.16,1.16,0,0,1,1.15,0h30a1.16,1.16,0,1,1,0,2.31Z" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <br></br>
        {showfilter ? (
          <section id="discover-1_5">
            <div className="row mb-5">
              <div className="col-lg-8 offset-lg-2">
                <div id="discover-form">
                  <div id="discover-form-bottom">
                    <div className="row">
                      <div className="col-lg-3 col-md-6">
                        <div className="form-group">
                          <select
                            className="form-control"
                            onChange={( e ) => {
                              setcountry( e.target.value );
                            }}
                          >
                            <option value>Country</option>
                            <option>UAE</option>
                            <option>KSA</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="form-group">
                          <select
                            className="form-control"
                            onChange={( e ) => {
                              setcity( e.target.value );
                            }}
                          >
                            <option value>City</option>
                            <option>City #1</option>
                            <option>City #2</option>
                            <option>City #3</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="form-group">
                          <select
                            className="form-control"
                            onChange={( e ) => {
                              setstate( e.target.value );
                            }}
                          >
                            <option value>State</option>
                            <option>Apartment</option>
                            <option>Villa</option>
                            <option>Furnished Flat</option>
                            <option>Farm House</option>
                            <option>Under Construction</option>
                            <option>Shop</option>
                            <option>Office Space</option>
                            <option>Warehouse</option>
                            <option>Land</option>
                            <option>Building</option>
                            <option>Compound</option>
                            <option>Hotel Apartments</option>
                            <option>Penthouse</option>
                          </select>
                          {state}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="form-group">
                          <select
                            className="form-control"
                            onChange={( e ) => {
                              setproptype( e.target.value );
                            }}
                          >
                            <option value>Property Type</option>
                            <option>Apartment</option>
                            <option>Villa</option>
                            <option>Furnished Flat</option>
                            <option>Farm House</option>
                            <option>Under Construction</option>
                            <option>Shop</option>
                            <option>Office Space</option>
                            <option>Warehouse</option>
                            <option>Land</option>
                            <option>Building</option>
                            <option>Compound</option>
                            <option>Hotel Apartments</option>
                            <option>Penthouse</option>
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <select className="form-control">
                          <option value>Amenities</option>
                          <option>Elevator</option>
                          <option>Powder Room</option>
                          <option>Maid Room</option>
                          <option>Laundry Room</option>
                          <option>Driver Room</option>
                          <option>Open Parking</option>
                          <option>Covered Parking</option>
                          <option>Free Parking</option>
                          <option>Terrace</option>
                          <option>Balcony</option>
                          <option>Courtyard</option>
                          <option>Garden</option>
                          <option>Children Play Area</option>
                          <option>Swimming Pool</option>
                          <option>Gym</option>
                          <option>Sports &amp; Recreation</option>
                          <option>WiFi</option>
                          <option>Central AC</option>
                          <option>Split AC</option>
                          <option>Window AC</option>
                          <option>Furnished</option>
                          <option>Un Furnished</option>
                          <option>Kitchen Cabinetry</option>
                          <option>Kitchen Appliances</option>
                          <option>Barbeque Area</option>
                          <option>Built in Wardrobes</option>
                          <option>Walk in Closet</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <select className="form-control">
                          <option value>Listed By</option>
                          <option>Agent</option>
                          <option>Owner</option>
                          <option>AqarChain</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <select className="form-control">
                          <option value>Currency</option>
                          <option>USD</option>
                          <option>SAR</option>
                          <option>AED</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <select className="form-control">
                          <option value>View Type</option>
                          <option>Floor Plans</option>
                          <option>Video Tours</option>
                          <option>360 Tours</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <select className="form-control">
                          <option value>Keywords</option>
                          <option>Keywords #1</option>
                          <option>Keywords #2</option>
                          <option>Keywords #3</option>
                        </select>
                      </div>
                    </div> */}
                    </div>
                    <div className>
                      <div className="col-lg-6 offset-lg-3 pt-4">
                        <div id="discover-form-bottom-buttons">
                          <button
                            className="btn btn-gradient-secondary"
                            type="button"
                          // onClick={() => {
                          //   callfilter(country, city, state, proptype);
                          // }}
                          >
                            Find
                          </button>
                          <button
                            className="btn btn-gradient-secondary"
                            type="button"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        <section id="discover-section_2" style={{ minHeight: "90vh" }}>
          <div className="container-lg">
            <div className="row pb-md-5">
              {allProperty.length !== 0 && (
                allProperty.map( ( item, index ) => (
                  <div className="col-xl-3 col-md-6 mb-5 mb-lg-2" key={index}>
                    <div className="discover-card has-box-shadow text-lg-left text-center">
                      <div className="card-body">
                        <p style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <span className="green-discount font-weight-bold mr-2">
                            {propblockdata.length !== 0 && propblockdata[index][6] / 10}% Discount
                          </span>{" "}
                          <span className="ml-2 font-weight-bold">
                            Via AQR Token{" "}
                            <img src="/public/images/AQR-Token.svg" />
                          </span>

                        </p>
                        <img
                          style={{
                            height: "300px", objectFit: "cover"
                          }}
                          className="card-img-top discover-card-image"
                          src={`https://token-api.aqarchain.io/${item.property.cover}`}
                          alt="discover-cardimage cap"
                        />
                        <div className="card-body" style={{ paddingBottom: "0" }}>
                          <div className="row">
                            <h3 className="discover-card-title font-weight-bold mb-3">
                              {item.propertyTitle}{" "}
                            </h3>
                          </div>
                          <div className="row">
                            <table
                              id="discover-table"
                              className="discover-table col-12"
                            >
                              <tbody>
                                <tr className="discover-table-border">
                                  <td className="discover-table-left discover-card-content">
                                    <span>Token Supply</span>
                                  </td>
                                  <td className="font-weight-bold discover-card-content">
                                    <span className="font-normal discover-table-left mr-2 ml-2">
                                      {" "}
                                      :{" "}
                                    </span>
                                    <span className="text-secondary">
                                      {propblockdata.length !== 0 && (
                                        `${pagenumber == 0
                                          ? propblockdata[index][0]
                                          : propblockdata[index + pagenumber * 10][0]
                                        } ${pagenumber == 0
                                          ? propblockdata[index][3]
                                          : propblockdata[index + pagenumber * 10][3]
                                        }`
                                      )}
                                    </span>
                                  </td>
                                </tr>
                                <tr className="discover-table-border">
                                  <td className="discover-table-left discover-token-value">
                                    <span>Token Value</span>
                                  </td>
                                  <td className="font-weight-bold">
                                    <span className="font-normal discover-table-left mr-2 ml-2">
                                      {" "}
                                      :{" "}
                                    </span>
                                    <span className="text-secondary">
                                      {propblockdata.length !== 0 && (
                                        `$ ${pagenumber == 0
                                          ? propblockdata[index][1]
                                          : propblockdata[index + pagenumber * 10][1]
                                        }`
                                      )}
                                    </span>
                                  </td>
                                </tr>
                                <tr className="discover-table-border">
                                  <td className="discover-table-left discover-card-content">
                                    <span>Gross Yeild </span>
                                  </td>
                                  <td className="font-weight-bold">
                                    <span className="font-normal discover-table-left mr-2 ml-2">
                                      {" "}
                                      :{" "}
                                    </span>
                                    <span className="text-secondary">
                                     
                                     $ {   item.AnnualRental.$numberDecimal  } 
                                     
                                    </span>
                                  </td>
                                </tr>
                                <tr className="discover-table-border discover-table-border-down">
                                  <td className="discover-table-left discover-card-content">
                                   <span> ROI</span>
                                  </td>
                                  <td className="font-weight-bold">
                                    <span className="font-normal discover-table-left mr-2 ml-2">
                                      :
                                    </span>{" "}
                                    <span className="text-secondary">
                                      {
                                        item.ROI.$numberDecimal
                                      }%
                                      {/* {
                                        propblockdata.length !== 0 && (
                                          pagenumber == 0
                                            ? ( propblockdata[index][1] *
                                              ( ( item.AnnualRental.$numberDecimal / propblockdata[index][4] ) * 100 ) ).toFixed( 2 )
                                            : ( propblockdata[index + pagenumber * 10][1] *
                                              ( ( item.AnnualRental / propblockdata[index][4] ) * 100 ) ).toFixed( 2 )
                                        )
                                      } */}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="col-12 mt-4 text-center d-flex justify-content-center">
                              <div className="property-clock-wrap">
                                <div className="text-secondary font-bold big property-clock">
                                  {/* <img src="/public/images/Timer-watch.svg" /> */}
                                  {
                                    propblockdata.length !== 0 && (
                                      propblockdata[index][5] > 0 && (
                                        <Timer
                                          unixtime={propblockdata[index][5]}
                                        />
                                      )
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "100%", marginTop: "1rem" }}>
                              <Link
                                style={{ width: "100%", display: "block" }}
                                to={{
                                  pathname: `/details/${item.property.id}`,
                                  state: {
                                    blockChainData: propblockdata.length !== 0 && propblockdata[index],
                                    property: item
                                  }
                                }}
                              >
                                <button className="btn btn-gradient" style={{ width: "100%" }}>view details</button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) )
              )}
              {allProperty.length !== 0 && (<div style={{ margin: "2rem auto", display: "flex", justifyContent: "center", width: "100%" }}>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pagecount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttns"}
                  nextLinkClassName={"nextBttns"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>)}
              
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default IsLoadingHOC( Discover2 );
