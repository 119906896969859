import {Route, Switch} from 'react-router-dom';
// import Authorization from './Authorization';
import { useSelector } from 'react-redux';
import landing from "../components/landing/landing";
import discover from "../components/discover/discover";
import discover2 from "../components/discover/discover2";
import discover3 from "../components/discover/discover3";


import AppRoute from './AppRoute';
import PublicLayout from '../layout/Public';
import details from "../components/details/details";
import Login from '../components/login/login';
import Dashboard from '../components/Dashboard/Dashboard';
import PrivateLayout from '../layout/Private';
import PrivateRoute from './PrivateRoute';
import Profile from '../components/Dashboard/Profile/Profile';
import KYC from '../components/Dashboard/Kyc/Kyc';
import Registration from '../components/Registration';

// const ALLUser = Authorization(['TEACHER', 'STUDENT', 'SCHOOL_ADMINISTRATOR']);
// const TeacherAndSchoolAdmin = Authorization( ["SCHOOL_ADMINISTRATOR", "TEACHER"] )
// const Teacher = Authorization( ['TEACHER'] )
// const SchoolAdmin = Authorization( ["SCHOOL_ADMINISTRATOR"] )



const Routing = () => {


    return (
        <Switch>
            <AppRoute
                exact
                path="/"
                component={landing}
                layout={PublicLayout} />
            
            <AppRoute
                exact
                path="/discover"
                component={discover2}
                layout={PublicLayout} />
            
            <AppRoute
                exact
                path="/discover2"
                component={discover2}
                layout={PublicLayout} />
            
            {/* <AppRoute
                exact
                path="/discover3"
                component={discover3}
                layout={PublicLayout} /> */}
            
            <AppRoute
                exact
                path="/details/:id"
                component={details}
                layout={PublicLayout} />
            
            <AppRoute
                exact
                path="/login"
                component={Login}
                layout={PublicLayout} />
            
            <AppRoute
                exact
                path="/registration"
                component={Registration}
                layout={PublicLayout} />

            <PrivateRoute
                exact
                path="/dashboard"
                component={Dashboard}
                layout={PrivateLayout} />
            
            <PrivateRoute
                exact
                path="/dashboard/profile"
                component={Profile}
                layout={PrivateLayout}
            />

            <PrivateRoute
                exact
                path="/dashboard/kyc"
                component={KYC}
                layout={PrivateLayout}
            />

            {/* <Route exact path="/discover" exact component={discover}></Route> */}
        </Switch>     
    )
}

export default Routing
