import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import CompanyMenu from './CompanyMenu';
import OwnerMenu from './OwnerMenu';
import AgentMenu from './AgentMenu';

const Sidebar = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  // let location = useLocation();
  // let user = useSelector(state => state.app.user);

  const logout = () => {
    //console.log("working");
    dispatch({type: 'LOGOUT'});
    history.push('/user-registration');
  };
  return (
    <nav id="sidebar">
      <div className="sidebar-top text-center">
        <div className="user-details-card has-border-radius p-4">
          <div className="user-profile-pic-section">
            {/* <UserProfile /> */}
            <span>
              <img
                alt=""
                className="edit-profile-pic"
                src={require("../../../assets/images/icon-edit-profile-picture-dashboard.svg").default}
              />
            </span>
          </div>
          <div className="user-details mt-2">
            <p className="mb-0 font-weight-bold text-capitalize">
              {/* <FullName /> */}
            </p>
          </div>
        </div>
      </div>
      <ul
        className="nav nav-tabs nav-tabs dashboard-nav-tabs flex-column has-border-radius mt-5 mb-5"
        id="company-nav-tabs"
        role="tablist">
        <OwnerMenu />
        {/* {user.role === 'COMPANY' && <CompanyMenu />}
        {user.role === 'USER' && <OwnerMenu />}
        <OwnerMenu />
        {user.role === 'AGENT' && <AgentMenu />} */}
      </ul>
      <ul className="nav nav-tabs dashboard-nav-tabs has-border-radius flex-column">
        <li className="nav-item">
          <div onClick={logout} className="nav-link" id="logout">
            Log Out
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
