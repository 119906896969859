import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import React, { useContext, useState } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./assets/css/theme.css";
import landing from "./components/landing/landing";
import discover from "./components/discover/discover";
import discover2 from "./components/discover/discover2";
import discover3 from "./components/discover/discover3";
import details from "./components/details/details";
import error from "./components/error/error";
import login from "./components/login/login";
import Dashboard from "./components/Dashboard/Dashboard";
import Routing from "./Routing";



function App() {
  function getLibrary(provider) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
  }

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <div className="App">
        <Router>
          <Routing />
          {/* <Switch>
            <Route exact path="/" exact component={landing}></Route>
            <Route exact path="/discover" exact component={discover}></Route>
            <Route exact path="/discover2" exact component={discover2}></Route>
            <Route exact path="/discover3" exact component={discover3}></Route>
            <Route exact path="/details" exact component={details}></Route>
            <Route exact path="/login" exact component={login}></Route>
            <Route exact path="/dashboard" exact component={Dashboard}></Route>
            <Route path="*" exact={true} component={error} />
          </Switch> */}
        </Router>
      </div>
      <ToastContainer />
    </Web3ReactProvider>
  );
}

export default App;
