import React from 'react'

const Step2 = ( props ) => {
    const { setActiveStep } = props
    return (
        <div className="py-md-7 p-5 mx-3 has-box-shadow">
            <div className="form-heading my-6 text-center">
                <img
                    alt=""
                    className="otp-verify-image mx-auto mb-3"
                    src="/images/icon-otp-vertification.svg"
                />
                <h2 className="font-weight-bold text-dark">
                    Congratulations!
                </h2>
                <h4 className="font-weight-bold text-dark">
                    Your mobile number is verified
                </h4>
                <h4 className="font-weight-bold text-dark">
                    successfully!
                </h4>
            </div>
            <button
                id="send-otp-button"
                className="btn btn-gradient-secondary w-100 my-5"
                onClick={() => setActiveStep( 3 )}
            >
                Continue
            </button>
        </div>
    )
}

export default Step2
