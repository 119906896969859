const Footer = () => {
    return (
        <footer className="footer aqarchain-2-footer text-md-left text-center bg-dark-dark-blue">
          <div className="container-lg">
            <div className="aqarchain-2-foote-row-1 row no-gutters pt-5 pb-4">
              <div className="col-lg-2 col-md-12 col-12 text-lg-left text-center">
                <img className="nav-brand" src="images/logo_white.svg" alt="website" width="200px" height="150px" />
              </div>
              <div className="col-lg-7 col-md-12 col-12 mt-2 mb-2 text-lg-left text-center">
                <div className="row">
                  <div className="col-lg-4 col-12">
                    <div className="footer-menu-nav">
                      <p className="nav-item"><a href="https://aqarchain.oss-me-east-1.aliyuncs.com/pitch-deck/Aqarchain-Presentation-English.pdf" className="footer-link cursor-pointer text-white big" target="_blank">One Pager</a></p>
                      <p className="nav-item"><a href="https://aqarchain.oss-me-east-1.aliyuncs.com/white-paper/White-Paper-English.pdf" target="_blank" className="footer-link cursor-pointer text-white big">White Paper</a></p>
                      <p className="nav-item"><a href="https://aqarchain.oss-me-east-1.aliyuncs.com/token-page/Aqarchain-Token-Page-English.pdf" className="footer-link cursor-pointer text-white big" target="_blank">Token Page</a></p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12">
                    <div className="footer-menu-nav">
                      <p className="nav-item"><a href="https://aqarchain.oss-me-east-1.aliyuncs.com/pitch-deck/Aqarchain-Presentation-English.pdf" className="footer-link cursor-pointer text-white big">Presentation</a></p>
                      <p className="nav-item"><a href="https://aqarchain.oss-me-east-1.aliyuncs.com/advisors/Aqarchain-Advisors-English.pdf" className="footer-link cursor-pointer text-white big" target="_blank">Advisors</a></p>
                      <p className="nav-item"><a href="https://aqarchain.oss-me-east-1.aliyuncs.com/team-members/Aqarchain-Team-Members-English.pdf" className="footer-link cursor-pointer text-white big" target="_blank">Team</a></p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12">
                    <div className="footer-menu-nav">
                      {/* <p className="nav-item"><a href="#" className="footer-link cursor-pointer text-white big">Terms &amp; Conditions</a></p>
                      <p className="nav-item"><a href="#" className="footer-link cursor-pointer text-white big">Privacy Policy</a></p> */}
                      {/* <p class="nav-item"><a href="#" class="footer-link cursor-pointer text-white big">One Pager</a></p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-12 mt-2 mb-2 text-center">
                <div className="d-inline-block text-lg-left text-center">
                  <ul className="nav social-nav flex-row">
                    <li className="nav-item mr-4"><a className="footer-link cursor-pointer" href="https://www.facebook.com/aqarchain.io" target="_blank"><i className="fab fa-2x text-white fa-facebook-f" /></a></li>
                    <li className="nav-item mr-4"><a className="footer-link cursor-pointer" href="https://twitter.com/aqarchain_io" target="_blank"><i className="fab fa-2x text-white fa-twitter" /></a></li>
                    <li className="nav-item"><a className="footer-link cursor-pointer" href="https://www.linkedin.com/company/aqar-chain/mycompany/" target="_blank"><i className="fab fa-2x text-white fa-linkedin-in" /></a></li>
                  </ul>
                  <ul className="nav social-nav flex-row">
                    <li className="nav-item mr-4"><a className="footer-link cursor-pointer" href="https://www.instagram.com/aqarchain/" target="_blank"><i className="fab fa-2x text-white fa-instagram" /></a></li>
                    <li className="nav-item mr-4"><a className="footer-link cursor-pointer" href="https://smartchain.medium.com/"><i className="fab fa-2x text-white fa-medium" /></a></li>
                    <li className="nav-item"><a className="footer-link cursor-pointer" href="https://t.me/aqrxtoken" target="_blank"><i className="fab fa-2x text-white fa-telegram-plane" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="aqarchain-2-foote-row-2 row no-gutters pt-4">
              <div className="col-lg-12 text-center mt-2 mb-4">
              <p className="copyright big mb-1 text-white font-normal">Aqarchain.io is currently operational in beta version, we appreciate your feedback and support through our telegram channel.</p> 
                <p className="copyright big mb-1 text-white font-normal">Copyright©2021 aqarchain.io, All rights reserved Smart Chain Holding Corporation.</p> 
                <p className="copyright big mb-1 text-white font-normal">Company No: 2075222 Intershore Chambers, Road Town, Tortola, British Virgin Islands</p>
              </div>
            </div>
          </div>
        </footer>
    )
}

export default Footer
