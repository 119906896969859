let initialState = {
    stateData: [],
    cityData: []
}

export default function ( state = initialState, action ) {
    switch ( action.type ) {
        case 'STATE_DATA':
            return { ...state, stateData: action.payload };
        case 'CITY_DATA':
            return { ...state, cityData: action.payload };
        default:
            return state
    }
}