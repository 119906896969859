import React from 'react'
import './error.css'
import errorr from './404@3x.png'
import { Link } from 'react-router-dom';
function error() {
    return (
        <div>
            <img className="bimg" src={errorr} alt="404 Error" />
            {/* heading start */}
            <p className="p">PAGE NOT FOUND</p>
            <p className="p1">The page you are looking for was moved <br /> renamed or might never existed</p>
            <div className="box">
                <Link to="/"><button className="btn1" type="submit"><b>Visit Home</b></button></Link>
                <Link to="/"> <button className="btn2" type="submit">Contact Us</button></Link>
            </div>
        </div>
    )
}

export default error
