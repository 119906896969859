import React from 'react'

function discover3() {
    return (
        <div>
        <section id="discover-section_1">
          <div className="container-lg">
            <div className="row align-items-center mb-5">
              <div id="discover-section_1-form" className="col-lg-5 col-sm-12">
                <div id="discover-form">
                  <div id="discover-form-top">
                    <div id="search-bar">
                      <div className="input-group">
                        <input id="search" type="text" className="form-control" placeholder="Search your next property ..." aria-label="search" aria-describedby="search" />
                        <div className="input-group-append">
                          <button className="btn btn-gradient" type="button">Search</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="discover-filter_options" className="col-lg-2 col-6 offset-lg-3">
                <div id="discover-sort_by" className="discover-filter_option justify-content-end">
                  <a className="alt-white text-decoration-none" href="/discover-properties2.html">
                    <span className="mr-3">Sort By</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className="icon-discover-filter_options">
                      <title>Sort By</title>
                      <g id="icon-discover-filter_options-sort_by-1" data-name="Layer 1">
                        <g id="icon-discover-filter_options-sort_by-2" data-name="Layer 2">
                          <g id="icon-discover-filter_options-sort_by-3" data-name="Layer 3">
                            <path d="M14.18,21.11,9.23,26.06V1.15a1.16,1.16,0,0,0-2.31,0V26.06L2,21.11A1.15,1.15,0,1,0,.34,22.74l6.92,6.92a1.14,1.14,0,0,0,.82.34,1.12,1.12,0,0,0,.81-.34l6.93-6.92a1.15,1.15,0,0,0,0-1.63,1.16,1.16,0,0,0-1.64,0Z" />
                            <path d="M29.66,7.26,22.74.34a1.15,1.15,0,0,0-1.63,0L14.18,7.26a1.15,1.15,0,0,0,0,1.63,1.16,1.16,0,0,0,1.64,0l4.95-5V28.85a1.16,1.16,0,0,0,2.31,0V3.94l5,5a1.14,1.14,0,0,0,.82.34,1.12,1.12,0,0,0,.81-.34,1.15,1.15,0,0,0,0-1.63Z" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
              <div id="discover-filter_options" className="col-lg-2 col-6">
                <div id="discover-filters" className="discover-filter_option">
                  <a className="alt-white text-decoration-none" href="/discover-properties3.html">
                    <span className="mr-3">Filter</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.31 30" className="icon-discover-filter_options">
                      <title>Filter</title>
                      <g id="icon-discover-filter_options-filter-1" data-name="Layer 1">
                        <g id="icon-discover-filter_options-filter-2" data-name="Layer 2">
                          <g id="icon-discover-filter_options-filter-3" data-name="Layer 3">
                            <path d="M28.85,11.54H3.46a1.16,1.16,0,0,1,0-2.31H28.85a1.16,1.16,0,0,1,0,2.31Z" />
                            <path d="M24.23,20.77H8.08a1.16,1.16,0,1,1,0-2.31H24.23a1.16,1.16,0,0,1,0,2.31Z" />
                            <path d="M19.62,30H12.69a1.16,1.16,0,0,1,0-2.31h6.93a1.16,1.16,0,0,1,0,2.31Z" />
                            <path d="M31.15,2.31h-30A1.16,1.16,0,0,1,1.15,0h30a1.16,1.16,0,1,1,0,2.31Z" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div> 
              </div>           
            </div>        
          </div>
        </section>
        <section className="discover-search-results">
          <div className="container-lg">
            <div className="row pb-md-5">
              <div className="col-12">
                <button className="btn btn-black mr-4 mb-2" type="button"> <span className="font-weight-normal">City : </span>Dubai  <i className="fas fa-times" /></button> 
                <button className="btn btn-black mr-4 mb-2" type="button"> <span className="font-weight-normal">Property Type : </span>Dubai  <i className="fas fa-times" /></button>
                <button className="btn btn-black mr-4 mb-2" type="button"> <span className="font-weight-normal">Amenities : </span>Dubai  <i className="fas fa-times" /></button>
                <button className="btn btn-black" type="button"> <span className="font-weight-normal">Listed BY : </span>Dubai  <i className="fas fa-times" /></button>
              </div>
            </div>
          </div>
        </section>
        <section id="discover-section_2">
          <div className="container-lg">
            <div className="row pb-md-5">
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
            <div className="row pb-md-5">
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
            <div className="row pb-md-5">
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
            <div className="row pb-md-5">
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
              <div className="col-xl-3 col-md-6 mb-5 mb-lg-2">
                <div className="discover-card has-box-shadow text-lg-left text-center">
                  <div className="card-body">
                    <p><span className="green-discount font-weight-bold mr-2">2% Discount</span> <span className="ml-2 font-weight-bold">Via AQR Token </span><img src="/public/images/AQR-Token.svg" /></p>
                    <img className="card-img-top discover-card-image" src="https://placehold.jp/72/1e282e/fae439/1200x1070.jpg?text=Search%20Result" alt="discover-cardimage cap" />
                    <div className="card-body">
                      <div className="row">
                        <h3 className="discover-card-title font-weight-bold mb-3">Luxury Villa in Dubai LOREM </h3>
                      </div>
                      <div className="row">
                        <table id="discover-table" className="discover-table col-12">
                          <tbody><tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Token Supply</td>
                              <td className="font-weight-bold discover-card-content"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">10.5M Prop Token</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-token-value">Token Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">0.0001 Token Name</span><br />
                                <span className="discover-table-left mr-2 ml-4">= 3500$</span></td>
                            </tr>
                            <tr className="discover-table-border">
                              <td className="discover-table-left discover-card-content">Per Share Value</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2"> : </span><span className="text-secondary">2600</span></td>
                            </tr>
                            <tr className="discover-table-border discover-table-border-down">
                              <td className="discover-table-left discover-card-content">ROI</td>
                              <td className="font-weight-bold"><span className="font-normal discover-table-left mr-2 ml-2">:</span> <span className="text-secondary">$25,000</span></td>
                            </tr>
                          </tbody></table>
                        <div className="col-12 mt-4 text-center d-flex justify-content-center">
                          <div className="property-clock-wrap">
                            <div className="text-secondary font-bold big property-clock"><img src="/public/images/Timer-watch.svg" /> 07D: 18H: 15:30 S</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          </div>
        </section>
      </div>
    )
}

export default discover3
