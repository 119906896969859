import React from "react";
import Countdown from "react-countdown";
import styled from "styled-components";


export const renderer = ({ days, hours, minutes, seconds, completed }) => {
  // if (completed) return <div>The Presale has started</div>;
  // else
  return (
    <DateGroup style={{ display: "flex" }}>
      {
        days > 0 && <DateItem>
        <DateLabel>DAYS</DateLabel>
        <DateValue>{`${days}`}</DateValue>
      </DateItem>
      }
    <DateItem>
      <DateLabel>HRS</DateLabel>
      <DateValue>{`${hours}`}</DateValue>
    </DateItem>
    <DateItem>
      <DateLabel>MIN</DateLabel>
      <DateValue>{`${minutes}`}</DateValue>
    </DateItem>
    <DateItem>
      <DateLabel>SEC</DateLabel>
      <DateValue>{`${seconds}`}</DateValue>
    </DateItem>
  </DateGroup>
  );
};

export const Timer = ({ unixtime }) => {
  // const unixDate = 111111111111;
  // window.alert(unixtime);
  let actualDate = new Date(parseFloat(unixtime) * 1000).toLocaleDateString(
    "en-US"
  );
  // window.alert(unixtime);
  // window.alert(Math.round(new Date().getTime() / 1000));

  return (
    <TimerContainer>
      <Countdown date={actualDate} renderer={renderer} />
    </TimerContainer>
  );
};
const TimerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1e282e;
  background-color: transparent;
  /* grid-area: 4 / 5 / 7 / 6; */
  grid-area: timer;
  border-radius: 6px;
`;

const CountDownContainer = styled.div``;

const Labels = styled.span`
  margin-left: 5px;
  color: #8888;
`;

const LabelContainer = styled.span`
  margin: 0 5px;
`;

const DateValue = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #f0f0f0;
  font-size: 15px;
  border-radius: 2px;
`;
const DateLabel = styled.span`
  font-size: 12px;
  opacity: 0.7;
`;

const DateGroup = styled.div`
  display: flex;
  gap: 0.5rem;
`;
const DateItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &::before {
    content: ":";
    position: absolute;
    right: -6px;
    bottom: 6px;
    color: rgba(255, 255, 255, 0.5);
  }
  &:last-child {
    &::before {
      display: none;
    }
  }
  p{
    margin-bottom:0;
  }
`;