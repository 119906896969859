import React from 'react';
import {useLocation, Link} from 'react-router-dom';

const OwnerMenu = () => {
  let location = useLocation();
  const CustomLink = ({url, className, id, title}) => {
    return (
      <li className="nav-item">
        <Link to={url} className={className} id={id}>
          {title}
        </Link>
      </li>
    );
  };
  return (
    <>
      <CustomLink
        url="/dashboard/profile"
        className={`nav-link ${
          location.pathname === '/dashboard/profile' ? 'active' : ''
        }`}
        id="company-nav-tab-my-profile-tab"
        title="My Profile"
      />
      <CustomLink
        url="/dashboard/kyc"
        className={`nav-link ${
          location.pathname === '/dashboard/kyc' ? 'active' : ''
        }`}
        id="user-dashboard-nav-tab-kyc-tab"
        title="KYC"
      />
      <CustomLink
        url="/dashboard/save-properties"
        className={`nav-link ${
          location.pathname === '/dashboard/save-properties' ? 'active' : ''
        }`}
        id="user-dashboard-nav-tab-my-saved-properties-tab"
        title="My Saved Properties"
      />
    </>
  );
};

export default OwnerMenu;
