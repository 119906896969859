import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getTotenizedProperty } from '../../Redux/action/App'

const SearchBar = ( props ) => {

    const { setAllProperty, setLoading, gettingPropDataFromBlockchain } = props

    const [searchText, setSearchText] = useState( "" )
    const dispatch = useDispatch()

    const onSearchHandler = async () => {
        setLoading( true )
        const body = {
            limit: "1000",
            filters: {
                propertyTitle: searchText
            }
        };
        await dispatch( getTotenizedProperty( body ) )
            .then(
                response => {
                    setAllProperty( response.data )
                    setLoading( false )
                    gettingPropDataFromBlockchain( response.data )
                }
                , error => {
                    console.log( "response", error );
                }
            )
            .catch(
                error => {
                    console.log( "response", error );
                }
            )
    }

    return (
        <div id="search-bar">
            <div className="input-group">
                <input
                    id="search"
                    type="text"
                    value={searchText}
                    onChange={e => setSearchText( e.target.value )}
                    className="form-control"
                    placeholder="Search your next property ..."
                    aria-label="search"
                    aria-describedby="search"
                />
                <div className="input-group-append">
                    <button className="btn btn-gradient" onClick={onSearchHandler} type="button">
                        Search
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SearchBar
