import { withoutAuthAxios } from "../../Config/axios"

export const login = data => async _dispatch => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( "api/auth/login", data )
            .then(
                response => {
                    resolve( response.data )
                    _dispatch( { type: "USER_DATA_UPDATE", payload: response.data.data } );
                    _dispatch( { type: "SAVE_TOKEN", payload: response.data.data.token } )
                },
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } )
}

export const signup = data => async _dispatch => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( "/auth/register", data )
            .then(
                response => {
                    resolve( response.data )
                    _dispatch( { type: "USER_DATA_UPDATE", payload: response.data.data.user } );
                    _dispatch( { type: "SAVE_TOKEN", payload: response.data.data.token } )
                },
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } )
}

export const forgotPassword = data => async _dispatch => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( "url", data )
            .then(
                response => resolve( response.data ),
                error => reject( error.response )
            )
            .catch(
                error => console.log( error )
            )
    } )
}


export const sendOTP = data => async () => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( "/api/auth/user/registration/send-otp/", data )
            .then(
                response => resolve( response.data ),
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } )
}


export const varifyOtp = data => async dispatch => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( "/api/auth/user/registration/verify-otp/", data )
            .then(
                response => resolve( response.data ),
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } );
};

export const registerUser = ( data, token ) => async _dispatch => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( "/api/auth/register/", data, { headers: { otp_token: `Bearer ${token}` } } )
            .then(
                response => {
                    resolve( response.data )
                    // _dispatch( { type: "USER_DATA_UPDATE", payload: response.data.user } );
                    // _dispatch( { type: "SAVE_TOKEN", payload: response.data.token } )
                },
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } );
};