import React from "react";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../connectors";
import WalletPopup from "../../components/Popup/Popup";
import WalletDetailsPopup from "../../components/WalletDetailsPopup/WalletDetailsPopup";
import { useEffect, useState } from "react";
import trimAdd from "../../utils/TrimAddress";
import { Link, useLocation, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {setupNetwork} from "../../utilss/wallet"



function Header ( props ) {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { active, account, activate, chainId } = useWeb3React();
  const [walletOpen, setWalletOpen] = useState( false );
  const closeModal = () => setWalletOpen( false );

  const [changePopupOpen, setChangePopupOpen] = useState( false );
  const closeModalChangePopup = () => setChangePopupOpen( false );


  const { token, user } = props;



  useEffect( () => {
    defaultConnect();
    
  }, [] );

  const defaultConnect = async () => {

    try {
      await activate( injected );
    } catch ( error ) {
      console.log( error );
    }
  };

  return (
    <div>
      {walletOpen && (
        <WalletPopup
          walletOpen={walletOpen}
          setWalletOpen={setWalletOpen}
          closeModal={closeModal}
        />
      )}
      {changePopupOpen && (
        <WalletDetailsPopup
          setWalletOpen={setWalletOpen}
          changePopupOpen={changePopupOpen}
          setChangePopupOpen={setChangePopupOpen}
          closeModal={closeModalChangePopup}
        />
      )}

      <header className={`header ${location.pathname === "/" ? "landing-page-header" : ""} `}>
        <div className="container-lg">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link className="navbar-brand mx-auto" to="/">
              <img
                className="logo-img"
                src={require( "../../assets/images/logo.svg" ).default}
                title="Aqarchain"
                alt="Aqarchain"
              />
            </Link>
            <div
              className="collapse navbar-collapse justify-content-xl-center justify-content-around"
              id="navbarNav"
            >
              <ul className="navbar-nav">
                <li className={`nav-item ${location.pathname === "/" && location.hash !== "#landing-page-faq-section_6" ? "active" : ""}`}>
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className={`nav-item ${location.pathname === "/discover" ? "active" : ""}`}>
                  <Link className="nav-link" to="/discover">
                    Discover
                  </Link>
                </li>
                {/* <li className={`nav-item ${location.hash === "#landing-page-faq-section_6" ? "active" : ""}`}>
                  <Link className="nav-link" to="/#landing-page-faq-section_6">
                    FAQ
                  </Link>
                </li> */}
              </ul>
            </div>

            {location.pathname !== "/discover" && location.pathname !== "/details" ? (
              <div style={{display: "flex",flexDirection: "column",alignItems: "flex-end"}}>
              <div className="navbar-brand mx-auto">

                <Link
                  to={token !== "" ? "/discover" : "/login"}
                  // to={token !== "" ? "/dashboard/profile" : "/login"}
                  className="btn btn-gradient btn-rounded w-212px"
                  type="button"
                >
                  {token !== "" ? `Welcome ${!user?.last_name ? "NA" : user?.last_name}` : "Login/Sign Up"}

                </Link>
                </div>
                {token !== "" && <button
                  onClick={e => {
                    dispatch( { type: "LOGOUT" } )
                    // history.push( "/" )
                  }}
                  style={{
                    border: "none",
                    padding: 0,
                    fontSize: "0.8rem",
                    position: "absolute",
                    bottom: 0,
                    right: "13px",
                    background: "transparent"
                  }}>Logout</button>}
              </div>
            ) : (
              <div className="navbar-brand mx-auto" href="/">
                <button
                  className="btn btn-gradient btn-rounded w-212px semi-small"
                  type="button"
                  onClick={active
                    ? () => setChangePopupOpen( true )
                    : () => setWalletOpen( true )
                  }
                >
                  {active ? <span style={{color:"green"}}>{trimAdd( account )}</span> : chainId?"Connect Wallet":<span style={{color:"red"}}>Switch to polygon</span>}
                </button>
              </div>
            )}

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
          </nav>
        </div>
      </header>

    </div>
  );
}


const mapStateToProps = state => {
  return ( {
    token: state.auth.token,
    user: state.auth.user
  } )
}

export default connect( mapStateToProps, {} )( Header );

